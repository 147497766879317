import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { isA11yChangeForViewAnnouncementEnabled } from 'feature-flags';
import { noop } from 'lodash';
import * as bp from 'view/styles/breakpoints';
import * as fonts from 'view/styles/fonts';
import * as mixins from 'view/styles/mixins';
import * as transitions from 'view/styles/transitions';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FakeButton } from '@atlassian/help-center-common-component/fake-button';
import messages from './messages';

export interface AnnouncementBannerDumbProps {
    isExpanded: boolean;
    isExpandable: boolean;
    expandBanner: () => void;
    shrinkBanner: () => void;
    onHeaderRef: (refNode: HTMLElement | null) => void;
    onMessageRef: (refNode: HTMLElement | null) => void;
    header?: string;
    messageHtml?: string;
    className?: string;
    /**
     * Useful when wanting to cap out the announcement to a particular width, but allow
     * the root container button to extend full width.
     */
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/ban-types
    InnerContainerComponent: React.ComponentType<React.PropsWithChildren<{}>>;
    removeBgColor?: boolean;
    isCursorGrab?: boolean;
}

export class AnnouncementBannerDumb extends React.Component<AnnouncementBannerDumbProps & InjectedIntlProps> {
    handleClick = () => {
        const { isExpanded, shrinkBanner, expandBanner } = this.props;
        isExpanded ? shrinkBanner() : expandBanner();
    };

    renderChevrons() {
        const { isExpandable, isExpanded, intl } = this.props;

        if (isA11yChangeForViewAnnouncementEnabled()) {
            if (!isExpandable) {
                return null;
            }

            return (
                <IconWrapBtn
                    type="button"
                    aria-expanded={isExpanded}
                    aria-controls="announcement-message"
                    aria-label={intl.formatMessage(messages.view)}
                    onClick={this.handleClick}
                    data-testid="view-announcement-btn"
                >
                    {isExpanded ? (
                        <ChevronUpIcon testId="chevronUpIcon" label="" />
                    ) : (
                        <ChevronDownIcon testId="chevronDownIcon" label="" />
                    )}
                </IconWrapBtn>
            );
        }
        if (isExpandable) {
            return isExpanded ? (
                <IconWrap>
                    <ChevronUpIcon label={intl.formatMessage(messages.shrink)} />
                </IconWrap>
            ) : (
                <IconWrap>
                    <ChevronDownIcon label={intl.formatMessage(messages.expand)} />
                </IconWrap>
            );
        }

        return null;
    }

    /**
     * This will stop propagation for any anchor element clicked or key pressed.
     * If we see a need for this elsewhere - this could be a good generic component with minimal lines
     * of code.
     */
    stopPropagationForLinks = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;
        if (target.tagName === 'A') {
            e.stopPropagation();
        }
    };

    render() {
        const {
            isExpanded,
            isExpandable,
            header,
            messageHtml,
            onMessageRef,
            onHeaderRef,
            className,
            InnerContainerComponent,
            removeBgColor,
            isCursorGrab,
        } = this.props;
        const handleClick = isExpandable ? this.handleClick : noop;
        const rootProps = isA11yChangeForViewAnnouncementEnabled()
            ? { role: 'region', 'aria-labelledby': 'announcement-header', skipFocus: true }
            : { onClick: handleClick, disabled: !isExpandable };
        if (!!header || !!messageHtml) {
            return (
                <Root
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    className={className}
                    removeBgColor={removeBgColor}
                    isExpanded={isExpanded}
                    isCursorGrab={isCursorGrab}
                    {...rootProps}
                >
                    <InnerContainerComponent>
                        <Container>
                            {/* eslint-disable-next-line styled-components-a11y/no-static-element-interactions */}
                            <AnnouncementMessage
                                onClick={this.stopPropagationForLinks}
                                onKeyDown={this.stopPropagationForLinks}
                                {...(isA11yChangeForViewAnnouncementEnabled()
                                    ? {}
                                    : {
                                          role: 'alert',
                                          'aria-live': 'assertive',
                                      })}
                            >
                                {header && (
                                    <Title id="announcement-header" innerRef={onHeaderRef} isExpanded={isExpanded}>
                                        {header}
                                    </Title>
                                )}
                                {messageHtml && (
                                    <Message
                                        id="announcement-message"
                                        innerRef={onMessageRef}
                                        isExpanded={isExpanded}
                                        dangerouslySetInnerHTML={{ __html: messageHtml }}
                                    />
                                )}
                            </AnnouncementMessage>

                            {/* only render chevon arrows if the banner is expandable */}
                            {this.renderChevrons()}
                        </Container>
                    </InnerContainerComponent>
                </Root>
            );
        }

        return null;
    }
}

export default injectIntl(AnnouncementBannerDumb);

/**
 * Watch out! Changing anything that will affect height (fontsize, padding, etc) in here will
 * break the clamping logic inside announcement-banner.tsx (look for SINGLE_LINE_TITLE_HEIGHT).
 * If you change anything here make sure it still works!
 */
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: ${token('space.300', '24px')};
    padding-bottom: ${token('space.300', '24px')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const IconWrap = styled.div`
    /* Important to not let this shrink in the flex container. Else it won't be visible in Chrome/Safari. */
    flex-shrink: 0;

    ${bp.fromSmall.css`
        /* Apply margin from mobile, when on a small viewport we want a bit of padding between the right edge and the icon. */
        margin-left: ${token('space.100', '8px')};
    `};

    svg {
        transition:
            background-color ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut},
            ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};
        border-radius: 50%;
    }
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const IconWrapBtn = styled.button`
    /* Important to not let this shrink in the flex container. Else it won't be visible in Chrome/Safari. */
    flex-shrink: 0;
    border: none;
    background: transparent;
    align-self: flex-start;
    cursor: pointer;

    ${bp.fromSmall.css`
        /* Apply margin from mobile, when on a small viewport we want a bit of padding between the right edge and the icon. */
        margin-left: ${token('space.100', '8px')};
    `};

    svg {
        transition:
            background-color ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut},
            ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};
        border-radius: 50%;
    }

    &.focus-visible,
    &:hover {
        svg {
            background-color: ${token('color.background.input', colors.N800)};
            color: ${token('color.text.inverse', colors.N0)};
            opacity: 0.7;
        }
    }
`;

interface RootProps {
    removeBgColor?: boolean;
    skipFocus?: boolean;
}
/**
 * Watch out! Changing anything that will affect height (fontsize, padding, etc) in here will
 * break the clamping logic inside announcement-banner.tsx (look for SINGLE_LINE_TITLE_HEIGHT).
 * If you change anything here make sure it still works!
 */
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Root = styled(FakeButton)<RootProps>`
    ${mixins.borderRadius};
    ${(props) => mixins.applyIf(!props.removeBgColor, mixins.subtleThemeColorBackground)};
    ${fonts.regular};
    display: block;
    width: 100%;
    text-align: left;
    transition: color ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};
    color: ${token('color.text', colors.N800)};
    /* Important to set box sizing so padding doesn't change the roots width. */
    box-sizing: border-box;
    /* Important to hide overflow because since we make the chevron bust out of the container, we don't want a scrollbar to appear. */
    overflow: hidden;

    &.focus-visible,
    &:hover ${IconWrap} {
        svg {
            background-color: ${token('color.background.input', colors.N800)};
            color: ${token('color.text.inverse', colors.N0)};
            opacity: 0.7;
        }
    }
`;

interface TitleProps {
    isExpanded?: boolean;
}

/**
 * Watch out! Changing anything that will affect height (fontsize, padding, etc) in here will
 * break the clamping logic inside announcement-banner.tsx (look for SINGLE_LINE_TITLE_HEIGHT).
 * If you change anything here make sure it still works!
 */
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Title = styled.div<TitleProps>`
    ${fonts.medium};
    ${(props) => mixins.applyIf(!props.isExpanded, mixins.clamp)};
    margin-bottom: ${token('space.100', '8px')};
    font-weight: 600;
`;

interface MessageProps {
    isExpanded: boolean;
}

/**
 * Watch out! Changing anything that will affect height (fontsize, padding, etc) in here will
 * break the clamping logic inside announcement-banner.tsx (look for SINGLE_LINE_TITLE_HEIGHT).
 * If you change anything here make sure it still works!
 */
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Message = styled.div<MessageProps>`
    /* We use multiline clamp because the message can be multi line, having unique P tags for each paragraph */
    /* If we use a single line clamp it won't work unfortunately. */
    ${(props) => mixins.applyIf(!props.isExpanded, mixins.multiLineClamp(1))}
`;

/**
 * Watch out! Changing anything that will affect height (fontsize, padding, etc) in here will
 * break the clamping logic inside announcement-banner.tsx (look for SINGLE_LINE_TITLE_HEIGHT).
 * If you change anything here make sure it still works!
 */
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const AnnouncementMessage = styled.div`
    display: flex;
    flex-direction: column;
    /* Explicit width needed for IE11 */
    width: 100%;
    overflow: hidden;
    /* This will make the chevron expand/shrink icon bust out of the container. */
    flex-shrink: 0;
`;
