/*
These resources are a temporary solution to help with the server side rendering of multi help center home page.
This module will not be needed, once we move to the Flexible home page built on top of the Layout system.
*/
import FeaturedAndSortedPortalsQuery from 'util/graphql-relay-connector/featured-and-sorted-portals/__generated__/featuredAndSortedPortalsQuery.graphql';
import BrandingQuery from 'util/graphql-relay-connector/help-center-branding/__generated__/helpCenterBrandingQuery.graphql';
import { createRelayResource } from 'util/resource-router';
import { fetchHelpCenterBrandingAction } from 'epics/model/help-center';
import { store } from 'providers';
import { createResource } from 'react-resource-router';
import { getRequest } from 'relay-runtime';
import { fetchFeaturedAndSortedPortalsModel } from 'state/actions/featured-and-sorted-portals';
import type { topicsQuery } from 'view/help-center-topics/topics/__generated__/topicsQuery.graphql';
import TopicsQuery from 'view/help-center-topics/topics/__generated__/topicsQuery.graphql';

export const brandingResource = createResource({
    type: 'RELAY_HELP_CENTER_BRANDING',
    getKey: (_, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        const request = getRequest(BrandingQuery);
        const params = request.params;
        return `${String(params.id)}${JSON.stringify({
            cloudId,
            helpCenterAri,
        })}`;
    },
    getData: () => {
        store.dispatch(fetchHelpCenterBrandingAction());
        return Promise.resolve(null);
    },
});

export const featuredAndSortedPortalsResource = createResource({
    type: 'RELAY_HELP_CENTER_PORTALS',
    getKey: (_, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        const request = getRequest(FeaturedAndSortedPortalsQuery);
        const params = request.params;
        return `${String(params.id)}${JSON.stringify({
            cloudId,
            helpCenterAri,
        })}`;
    },
    getData: () => {
        store.dispatch(fetchFeaturedAndSortedPortalsModel({}, false));
        return Promise.resolve(null);
    },
});

export const topicsResource = createRelayResource<topicsQuery>({
    type: 'HELP_CENTER_TOPICS',
    getQuery: (_, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        return {
            parameters: TopicsQuery,
            variables: {
                cloudId,
                helpCenterAri,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
