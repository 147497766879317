import * as React from 'react';
import { useIsoMorphicLayoutEffect } from 'util/hooks';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { di } from 'react-magnetic-di';
import { getThemeColor } from 'state/selectors/help-center';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { setGlobalTheme } from '@atlaskit/tokens';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import type { IThemingContainerProps } from './types';
import { OUTCOME } from './types';

export const ThemingComponent = (props: IThemingContainerProps) => {
    di(setGlobalTheme, useAnalyticsEvents);
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const { children, themeColor } = props;

    const sendAnalyticsEvent = React.useCallback(
        (action: OUTCOME) => {
            const analyticsEvent = createAnalyticsEvent({
                action,
                analyticsType: OPERATIONAL_EVENT_TYPE,
                actionSubject: 'portalThemingBootstrap',
            });
            analyticsEvent.fire();
        },
        [createAnalyticsEvent]
    );

    useIsoMorphicLayoutEffect(() => {
        setGlobalTheme({
            // @ts-expect-error
            light: '',
            spacing: 'spacing',
            colorMode: 'light',
        })
            .then(() => sendAnalyticsEvent(OUTCOME.SUCCESS))
            .catch(() => sendAnalyticsEvent(OUTCOME.FAILURE));
    }, [sendAnalyticsEvent, themeColor]);

    return <>{children}</>;
};

const selector = createSelector(getThemeColor, (themeColor) => ({ themeColor }));
export const ThemingContainer = connect(selector)(ThemingComponent);
