import { initialModel } from 'util/model';
import { SUCCESSFUL_VERIFICATION_OUTCOMES } from 'view/verify-email/constants';
import type { FAILED_VERIFICATION_OUTCOMES } from 'view/verify-email/constants';

export interface VerifyEmailState {
    signupToken: string;
    emailAddress: string;
    validationOutcome: FAILED_VERIFICATION_OUTCOMES | SUCCESSFUL_VERIFICATION_OUTCOMES;
    portalName: string | null;
    portalId: number | null;
}

const defaultState: VerifyEmailState = {
    signupToken: '',
    emailAddress: '',
    validationOutcome: SUCCESSFUL_VERIFICATION_OUTCOMES.SUCCEEDED,
    portalName: null,
    portalId: null,
};

export const verifyEmailReducer = (state: VerifyEmailState = getInitialState()): VerifyEmailState => state;

export const getInitialState = (): VerifyEmailState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.verifyEmail) {
        return initialModelState.verifyEmail;
    }
    return defaultState;
};
