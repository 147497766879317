/**
 * Define feature flag here as constants.
 *
 * export const KB_ENHANCEMENTS = 'jsd.px.kb-enhancements';
 *
 * Use them in react components.
 * import { KB_ENHANCEMENTS } from 'feature-flags';
 * import { FeatureFlag } from '@atlassian/help-center-common-component/feature-flag';
 *
 * <FeatureFlag name={KB_ENHANCEMENTS}>
 *  I will be shown in true :-)
 * </FeatureFlag>
 *
 * ========== NOTE ===================
 * BE CAREFUL ADDING FEATURE FLAGS, IF YOU RENAME THEM IT WILL CHANGE THE ANALYTIC CONTEXT
 * NAME AND NO LONGER SEND THE SAME CONTEXT ATTRIBUTE NAME.
 * ===================================
 */

import FeatureGates from '@atlaskit/feature-gate-js-client';
// Be careful when using these constants directly. In most cases, you should use the function.
export const RICH_TEXT_AREA_FIELD = 'jsd-use-adf-default-value-for-textarea';
export const ENHANCED_RENDER_EXPERIENCE_FOR_KNOWLEDGE_ARTICLES = 'jsm-enhanced-render-exp-for-knowledge-articles';

const ENABLE_KB_ARTICLES_OPEN_IN_SAME_TAB = 'make-kb-articles-open-in-same-tab';

export const REDIRECT_TO_LOGIN_PAGE_ON_INVALID_TOKEN_FOR_HOME_PAGE = 'redirect_to_login_for_hc_home_page_un_authorized';
export const UNIFIED_HELP_REPORTING_PAGE = 'help_center_reporting_route';
export const UNIFIED_HELP_ADD_FILTER_FOR_CUSTOMER_ACCOUNTS = 'unified-help-add-filter-for-customer-accounts';

export const BASIC_HC_CALLS_JIRA = 'basic_hc_calls_jira';

const JCSM_CS_MASTER_FLAG = 'jcs_master_flag';
const CSM_PREMIUM_SLA_DISABLED_FLAG = 'jsm_csm_premium_sla_config';
export const CSM_REQUEST_INTAKE_M1_GATE = 'csm-request-intake-m1';
export const ENABLE_HC_LAYOUT_GRANULAR_INTERACTION = 'enable-hc-layout-granular-interactions';
export const JSM_VIRTUAL_AGENT_ANONYMOUS_ACCESS = 'anonymous_virtual_agent_access';

export const JSM_VIRTUAL_AGENT_ON_HELPCENTER_ESCAPE_HATCH = 'jsm_virtual_agent_on_helpcenter_escape_hatch';
export const ENABLE_REACT_18_CREATEROOT = 'enable_react-18';
export const DONT_USE_HYDRATE_SSR = 'dont_use_hydrate_in_portal_ssr';
export const FIX_EXTERNAL_LINK_BUG = 'external_link_click_event_bug_fix';

const FIX_RELAY_ID_CONFLICT_IN_HELP_CENTER = 'fix_relay_id_conflict_in_help_center';
const A11Y_FIX_FOR_REMOVE_ATTACHMENT_BUTTON = 'a11y_fix_for_remove_attachment_button';
const ADVANCED_HELP_CENTER_CUSTOMIZATION_EDIT_PAGE = 'advanced_help_center_customization_edit_page';
const UPDATE_TEXT_FIELD_PACKAGE_VERSION_FOR_INPUT_FIELD = 'update_text_field_package_version_for_input_field';
const REMOVE_TRANSLATIONS_FALLBACK = 'remove_translations_fallback';
const TUNING_RETRY_MECHANISM_FOR_MODELS_ENDPOINT = 'tuning_model_retries';
const FILTER_PREMIUM_SLA_ERRORS = 'filter_premium_sla_errors';
const HELP_CENTER_DROPDOWN_REDESIGN = 'help_center_dropdown_redesign';
const CUSTOMISATION_BUTTON_CHANGE_BOARDING = 'enable_customisation_button_change_boarding';
const PROJECT_MAPPING_ENABLED_IN_MULTI_HC = 'enable_project_mapping_for_help_center';
const RECENT_FORMS_ERROR_BOUNDARY = 'recent_forms_error_boundary';
const CANCLE_APPROVAL_ACTIVITY_HISTORY = 'start-1399-surface-cancel-approval-into-activity';
const SUMMARY_FIELD_A11Y_FIXES = 'summary_field_a11y_fixes';
const FILTER_NULL_TRACE_ID_FROM_GRAPHQL_CALLS = 'filter_null_trace_id_from_graphql_calls';
const JSM_RESTRICTED_REQUEST_TYPE_M2_BUGS = 'restricted_request_type_m2_bugs';
const USE_NEW_EDITOR_CARD_PROVIDER = 'use_new_editor_card_provider';
const VA_LOADER_MESSAGE_BASED_ON_TIME_INTERVAL = 'va_on_portal_ai_answer_time_interval_based_spinner';
const REMOVE_SLASH_FROM_HOMEPAGE_ROUTE = 'remove_slash_from_homepage_routes';
const ENABLE_CROSS_SITE_CONFLUENCE = 'enable-cross-site-confluence';
const PROFORMA_TTVC_UPDATE_PACKAGE_ENABLED = 'measure_proforma_ttvc_impact';
const HANDLE_FOLLOW_UP_QUERIES_ON_VA = 'handling_followupquery_on_feedback_response';
const JSM_SEARCH_UI_REDESIGN = 'jsm_help_center_search_ui_redesign';
const REMOVE_CREATE_REQUEST_REDUNDANT_MODELS = 'jsm-create-request-redundant-models-fe';
const VA_OMNICHANNEL_ENABLED = 'va_on_hc_and_portal_toggle_rest_or_graphql';
const ENABLE_RELAY_SUBSCRIPTIONS = 'enable_subscription_in_relay';
const JSM_SEARCH_UI_REDESIGN_CONTENT = 'jsm_help_center_search_ui_redesign_content';
const JSM_LOCK_SCIM_MANAGED_CUSTOMER_AND_ORG = 'jsm_lock_scim_managed_customer_and_org';
const CONSUME_LOOSELY_LAZY_MANIFEST_IN_SSR = 'consume_loosely_lazy_manifest_in_ssr';
const JSM_SEARCH_EXPERIENCE_SLO_UPDATE = 'jsm_help_center_search_fama_experience-slo-update';
const DEFAULT_HC_USING_GQL_BRANDING = 'default_hc_using_gql_branding';
const MOVE_ADMIN_SCREENS_TO_HC_MANAGEMENT_ROUTER = 'move-admin-screens-to-management-router';
const PROJECT_MAPPING_CHANGE_BOARDING = 'project_mapping_change_boarding';
const HELP_CENTER_REFRESH_FLAG = 'help_center_refresh';
const HELP_CENTER_TRACEABLE_AJAX = 'help_center_traceable_ajax';
const GOOGLE_TRANSLATE_ISSUE_WORKAROUND = 'google_translate_issue_workaround';
const REMOVE_EMOJI_FROM_SSR = 'help_desk_remove_emoji_from_ssr';
const ENABLE_FORGE_APPS_IN_JSM_PHASE_1 = 'enable_forge_apps_in_jsm_layout_builder_phase_1';
const DISABLE_OMNICHANNEL_HC = 'disable_omnichannel_for_hc';
const UNABLE_ANALYTICS_FAILURE_EVENT_WITH_ERROR = 'update_analytics_failure_event_with_error';
const REMOVE_FLEXIHC_BETA_TAG_FOR_GA = 'remove_flexihc_beta_tag_for_ga';
const FIX_PROJECT_MAPPING_P1_BUGS = 'fix_project_mapping_p1_bugs';
const A11Y_FIX_FOR_PORTALS_GROUP_HEADING = 'a11y_fix_for_portal_groups_heading_';
const A11Y_CHANGE_FOR_VIEW_ANNOUNCEMENT = 'a11y_change_for_view_announcement';
export const EMAIL_VERIFIED_SIGNAL_ON_CUSTOMERS_PAGE = 'sptn-1697-email-verified-signal-on-customers-page';
const NEW_TEXT_FIELD_ENABLED_FOR_FORM = 'new-text-field-enabled-for-form';

export const DAYONE_FOCAQ_EXPERIMENT = 'jsm_1d_fo_core_actions';
export const DAYONE_FOCAQ_FEATURE_GATE = 'jsm_1d_fo_core_actions_gate';
export const PROFORMA_ACCESSIBILITY_IMPROVEMENTS = 'proforma_accessibility_improvements';
export const SSR_HELP_CENTER_UFO_CONFIG = 'ssr_help_center_ufo_config';
export const STRING_LENGTH_VALIDATION_WITHOUT_TRIM = 'string_length_validation_without_trim';
export const ENABLE_FOLLOWUP_QUESTIONS_ON_PORTAL = 'enable_followup_questions_on_omni_channel_portal';

export const isA11yFixForRemoveAttachmentButtonEnabled = () =>
    FeatureGates.checkGate(A11Y_FIX_FOR_REMOVE_ATTACHMENT_BUTTON);
export const ENABLE_HELPCENTER_ARI_AI_ANALYTICS_METADATA = 'enable_helpcenter_ari_in_ai_analytics_metadata';

export const isGranularLayoutInteractionTestEnabled = () =>
    FeatureGates.checkGate(ENABLE_HC_LAYOUT_GRANULAR_INTERACTION);

export const is1DFOCAQExperimentEnabled = () =>
    FeatureGates.getExperimentValue<string>(DAYONE_FOCAQ_EXPERIMENT, 'cohort', 'not-enrolled');

export const is1DFOCAQFeatureGateEnabled = () => FeatureGates.checkGate(DAYONE_FOCAQ_FEATURE_GATE);

export const isEmailVerifiedSignalEnabled = () => {
    return FeatureGates.checkGate(EMAIL_VERIFIED_SIGNAL_ON_CUSTOMERS_PAGE);
};

// TODO: remove this code, we are marking this true given timeline for LD Deprecation
export const isWrmOptimizationsEnabled = () => true;

export const isRichTextAreaEnabled = () => FeatureGates.checkGate(RICH_TEXT_AREA_FIELD);

export const isEnhanceRenderExperienceForKnowledgeArticlesEnabled = () =>
    FeatureGates.checkGate(ENHANCED_RENDER_EXPERIENCE_FOR_KNOWLEDGE_ARTICLES);

export const isRedirectToLoginPageOnInvalidTokenForHomePage = () =>
    FeatureGates.checkGate(REDIRECT_TO_LOGIN_PAGE_ON_INVALID_TOKEN_FOR_HOME_PAGE);

export const islooselyLazyJsErrorsToSentryEnabled = () =>
    FeatureGates.checkGate('throw_lazy_error_boundary_js_errors_to_sentry');

/*
 * Cleaning up the LD feature flag 'jsm-portal-custom-theming'
 * https://app.launchdarkly.com/jira/production/features/jsm-portal-custom-theming
 * Custom themes were not rolled out.
 *
 * */
export const isCustomThemingEnabled = () => false;

export const isUnifiedHelpReportingPageEnabled = () => {
    return FeatureGates.checkGate(UNIFIED_HELP_REPORTING_PAGE);
};

export const isProjectMappingInHelpCenterEnabled = () => {
    return FeatureGates.checkGate(PROJECT_MAPPING_ENABLED_IN_MULTI_HC);
};

export const isUnifiedHelpAddFilterForCustomerAccounts = () => {
    return FeatureGates.checkGate(UNIFIED_HELP_ADD_FILTER_FOR_CUSTOMER_ACCOUNTS);
};

export const isFixRelayIdConflictInHelpCenter = () => {
    return FeatureGates.checkGate(FIX_RELAY_ID_CONFLICT_IN_HELP_CENTER);
};

// Virtual Service Agent (JSM Flux) Feature flags
export const isJSMVirtualAgentAnonymousAccessEnabled = () => FeatureGates.checkGate(JSM_VIRTUAL_AGENT_ANONYMOUS_ACCESS);

// JSM Boysenberry feature flags
export const isJsmCsMasterFlagEnabled = () => {
    return FeatureGates.checkGate(JCSM_CS_MASTER_FLAG);
};

// CSM Premium SLA disabled flag
export const isCsmPremiumSlaDisabledFlagEnabled = () => {
    return FeatureGates.checkGate(CSM_PREMIUM_SLA_DISABLED_FLAG);
};

export const isCSMRequestIntakeM1Enabled = () => {
    return FeatureGates.checkGate(CSM_REQUEST_INTAKE_M1_GATE);
};

export const isDefaultHCCallsJiraEnabled = () => {
    return FeatureGates.checkGate(BASIC_HC_CALLS_JIRA);
};

export const isSanitizationOfPortalDescriptionToRemoveTabIndexEnabled = () => {
    return FeatureGates.checkGate('sanitize_portal_card_description_tabi_index');
};

export const isCrossSiteConnieConnectionEnabled = () => {
    return FeatureGates.checkGate(ENABLE_CROSS_SITE_CONFLUENCE);
};

export const isKbArticlesSameTabRedirectionEnabled = () => {
    return FeatureGates.checkGate(ENABLE_KB_ARTICLES_OPEN_IN_SAME_TAB);
};

export const isAdvancedCustomizationEnabled = () => {
    return FeatureGates.checkGate('advanced_help_center_customization');
};

export const isAdvancedCustomizationEditPageEnabled = () => {
    return FeatureGates.checkGate(ADVANCED_HELP_CENTER_CUSTOMIZATION_EDIT_PAGE);
};

export const isNewTextFieldEnabled = () => {
    return FeatureGates.checkGate(UPDATE_TEXT_FIELD_PACKAGE_VERSION_FOR_INPUT_FIELD);
};

export const isUseReact18CreateRootEnabled = () => {
    return FeatureGates.checkGate(ENABLE_REACT_18_CREATEROOT);
};

export const isReactHydrateDisabledInSSR = () => {
    return FeatureGates.checkGate(DONT_USE_HYDRATE_SSR);
};

export const fixExternalLinkBug = () => {
    return FeatureGates.checkGate(FIX_EXTERNAL_LINK_BUG);
};

export const isRemoveTranslationsFallbackEnabled = () => {
    return FeatureGates.checkGate(REMOVE_TRANSLATIONS_FALLBACK);
};

export const isPortalCardMarkupFixEnabled = () => {
    return FeatureGates.checkGate('fix_portal_card_markup');
};

export const isVAOnHelpCenterEscapeHatchEnabled = () => {
    return FeatureGates.checkGate(JSM_VIRTUAL_AGENT_ON_HELPCENTER_ESCAPE_HATCH);
};

export const isHelpCenterNewDropdownEnabled = () => {
    return FeatureGates.checkGate(HELP_CENTER_DROPDOWN_REDESIGN);
};

export const isCustomiseDropDownChangeBoardingEnabled = () => {
    return FeatureGates.checkGate(CUSTOMISATION_BUTTON_CHANGE_BOARDING);
};

export const isFilterNullTraceIdFromGraphqlCallsEnabled = () => {
    return FeatureGates.checkGate(FILTER_NULL_TRACE_ID_FROM_GRAPHQL_CALLS);
};

export const isPremiumSLAErrorFilterEnabled = () => {
    return FeatureGates.checkGate(FILTER_PREMIUM_SLA_ERRORS);
};

export const isMoveToManagementRouterEnabled = () => {
    return FeatureGates.checkGate(MOVE_ADMIN_SCREENS_TO_HC_MANAGEMENT_ROUTER);
};

export const isRecentFormsErrorBoundaryEnabled = () => {
    return FeatureGates.checkGate(RECENT_FORMS_ERROR_BOUNDARY);
};

export const isRetryMechanismTuningEnabled = () => {
    return FeatureGates.checkGate(TUNING_RETRY_MECHANISM_FOR_MODELS_ENDPOINT);
};

export const isCancelApprovalActivityStreamEnabled = () => {
    return FeatureGates.checkGate(CANCLE_APPROVAL_ACTIVITY_HISTORY);
};

export const isNewSummaryFieldA11yEnabled = () => {
    return FeatureGates.checkGate(SUMMARY_FIELD_A11Y_FIXES);
};

export const isRestrictedRequestTypeM2BugsEnabled = () => {
    return FeatureGates.checkGate(JSM_RESTRICTED_REQUEST_TYPE_M2_BUGS);
};
export const isUseNewEditorCardProviderEnabled = () => {
    return FeatureGates.checkGate(USE_NEW_EDITOR_CARD_PROVIDER);
};

export const isVATimeIntervalBasedLoaderMessageEnabled = () => {
    return FeatureGates.checkGate(VA_LOADER_MESSAGE_BASED_ON_TIME_INTERVAL);
};

export const isA11yFixForPortalsGroupHeadingEnabled = () => {
    return FeatureGates.checkGate(A11Y_FIX_FOR_PORTALS_GROUP_HEADING);
};

export const isProformaTTVCUpdateMeasureEnabled = () => {
    return FeatureGates.checkGate(PROFORMA_TTVC_UPDATE_PACKAGE_ENABLED);
};

export const isVAFollowupQueryOnlyForPositiveResponseEnabled = () => {
    return FeatureGates.checkGate(HANDLE_FOLLOW_UP_QUERIES_ON_VA);
};

export const isSlashOptionalInHelpCenterHomeRoute = () => {
    return FeatureGates.checkGate(REMOVE_SLASH_FROM_HOMEPAGE_ROUTE);
};

export const isSearchUIRedesignEnabled = () => {
    return FeatureGates.checkGate(JSM_SEARCH_UI_REDESIGN);
};

export const isRemoveRedundantModelsCreateRequest = () => {
    return FeatureGates.checkGate(REMOVE_CREATE_REQUEST_REDUNDANT_MODELS);
};

export const isOmniChannelEnabled = () => {
    return FeatureGates.checkGate(VA_OMNICHANNEL_ENABLED);
};

export const isOmniChannelDisabledForHC = () => {
    return FeatureGates.checkGate(DISABLE_OMNICHANNEL_HC);
};

export const enableRelaySubscriptions = () => {
    return FeatureGates.checkGate(ENABLE_RELAY_SUBSCRIPTIONS);
};

export const isSearchUIRedesignContentEnabled = () => {
    return FeatureGates.checkGate(JSM_SEARCH_UI_REDESIGN_CONTENT);
};

export const isVAOnHelpCenterGAEnabled = () => {
    return FeatureGates.checkGate('va_on_help_center_ga');
};

export const isConsumeLooselyLazyManifestInSSREnabled = () => {
    return FeatureGates.checkGate(CONSUME_LOOSELY_LAZY_MANIFEST_IN_SSR);
};

export const isJsmScimLockEnabled = () => {
    return FeatureGates.checkGate(JSM_LOCK_SCIM_MANAGED_CUSTOMER_AND_ORG);
};

export const isMigrateToAnalyticsBridgeEnabled = () => {
    return FeatureGates.checkGate('jsm-portal-migrate-to-analytics-bridge');
};

export const isSearchFAMASLOUpdateEnabled = () => {
    return FeatureGates.checkGate(JSM_SEARCH_EXPERIENCE_SLO_UPDATE);
};

export const isDefaultHCUsingGqlBrandingCalls = () => {
    return FeatureGates.checkGate(DEFAULT_HC_USING_GQL_BRANDING);
};

export const isProjectMappingChangeBoardingEnabled = () => {
    return FeatureGates.checkGate(PROJECT_MAPPING_CHANGE_BOARDING);
};

export const isUseHelpCenterRefresh = () => {
    return FeatureGates.checkGate(HELP_CENTER_REFRESH_FLAG);
};

export const isUseGoogleTranslateIssueWorkaround = () => {
    return FeatureGates.checkGate(GOOGLE_TRANSLATE_ISSUE_WORKAROUND);
};

export const isRemoveEmojiFromSSR = () => {
    return FeatureGates.checkGate(REMOVE_EMOJI_FROM_SSR);
};

export const isForgeAppsInJSMPhase1Enabled = () => FeatureGates.checkGate(ENABLE_FORGE_APPS_IN_JSM_PHASE_1);

export const isUiModificationsSupportEnabled = () => FeatureGates.checkGate('ditto_uim_-_jsm_support_main');

export const isUpdateAnalyticsFailureEventWithError = () =>
    FeatureGates.checkGate(UNABLE_ANALYTICS_FAILURE_EVENT_WITH_ERROR);

export const isA11yChangeForViewAnnouncementEnabled = () => {
    return FeatureGates.checkGate(A11Y_CHANGE_FOR_VIEW_ANNOUNCEMENT);
};

export const isBetaTagRemovedForFlexiHCGA = () => {
    return FeatureGates.checkGate(REMOVE_FLEXIHC_BETA_TAG_FOR_GA);
};

export const isProjectMappingP1BugsEnabled = () => {
    return FeatureGates.checkGate(FIX_PROJECT_MAPPING_P1_BUGS);
};

export const isProformaAccessibilityImprovementsEnabled = () => {
    return FeatureGates.checkGate(PROFORMA_ACCESSIBILITY_IMPROVEMENTS);
};

export const isTraceableAjaxEnabled = () => {
    return FeatureGates.checkGate(HELP_CENTER_TRACEABLE_AJAX);
};

export const isSSRUFOConfigEnabled = () => {
    return FeatureGates.checkGate(SSR_HELP_CENTER_UFO_CONFIG);
};

export const isStringLengthValidationWithoutTrimEnabled = () => {
    return FeatureGates.checkGate(STRING_LENGTH_VALIDATION_WITHOUT_TRIM);
};

export const isFollowupEnabledForPortal = () => {
    return FeatureGates.checkGate(ENABLE_FOLLOWUP_QUESTIONS_ON_PORTAL);
};

export const isHelpcenterAriEnabledInAnalyticsMetaData = () => {
    return FeatureGates.checkGate(ENABLE_HELPCENTER_ARI_AI_ANALYTICS_METADATA);
};

export const isNewTextFieldEnabledForForm = () => {
    return FeatureGates.checkGate(NEW_TEXT_FIELD_ENABLED_FOR_FORM);
};

export const isUimForgeRendererPropsEnabled = () => {
    return FeatureGates.checkGate('ditto_uim_-_forge_renderer_props');
};

export const isAiAnswer24HourTimeoutEnabled = () => {
    return FeatureGates.checkGate('ai_answer_default_timeout_to_24_hours');
};
