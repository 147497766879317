import { useRequestTypeFields } from '../../services/request-type-fields-service';
import { isTextField } from '../../services/request-type-fields-service/utils';
import { useActions, useFieldFromLastMessage, useBaseUrl } from '../conversation-store';

export const useSendMessage = () => {
    const { addMessage, addJiraFieldMessage } = useActions();
    const jiraField = useFieldFromLastMessage();
    const baseUrl = useBaseUrl();
    const [{ data: requestTypeFields }] = useRequestTypeFields({ baseUrl });

    const sendMessage = (message: string) => {
        if (jiraField && isTextField(jiraField.fieldId, requestTypeFields)) {
            addJiraFieldMessage(jiraField.fieldId, message);
        } else {
            addMessage(message);
        }
    };

    return { sendMessage };
};
