import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { Category } from 'rest/category';
import * as fonts from 'view/styles/fonts';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import BookIcon from '@atlaskit/icon/glyph/book';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { GROUP_BOX_PADDING_LEFT_RIGHT } from '@atlassian/help-center-common-component/group-box';
import type { CardLinkAppearance } from '@atlassian/help-center-common-component/group-box-select';
import { RaiseRequestLink } from '@atlassian/help-center-common-component/raise-request-link';

import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import { CategoryItem } from './category-item';
import messages from './messages';

export interface CategoryListProps {
    categories: Category[];
    portalId: number;
    portalLink: string;
    hidden: boolean;
    className?: string;
    cardLinkAppearance?: CardLinkAppearance;
    showRequestCreateButton?: boolean;
    isEmbeddedRoute?: boolean;
}

export default class CategoryList extends React.Component<CategoryListProps> {
    static defaultProps = {
        portalId: undefined,
        categories: [],
    };

    render() {
        const {
            portalId,
            categories,
            portalLink,
            hidden,
            cardLinkAppearance,
            className,
            showRequestCreateButton,
            isEmbeddedRoute,
        } = this.props;

        if (hidden || !portalId || categories.length === 0) {
            return null;
        }

        const analyticData = {
            attributes: {
                numberOfCategories: categories.length,
            },
        };

        return (
            <AnalyticsContext data={analyticData}>
                {/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
                <Root className={className}>
                    <FireScreenEventOnMount />
                    <CategorySectionTitle>
                        <BookIcon label="" aria-hidden="true" primaryColor={token('color.icon', colors.N500)} />
                        <FormattedMessage {...messages.kbCategorySectionTitle} />
                    </CategorySectionTitle>

                    <CategoryItems>
                        {categories.map((category) => (
                            <CategoryItemContainer key={category.id}>
                                <CategoryItem
                                    portalId={portalId}
                                    category={category}
                                    isEmbeddedRoute={isEmbeddedRoute}
                                />
                            </CategoryItemContainer>
                        ))}
                    </CategoryItems>
                    {showRequestCreateButton && (
                        <RaiseRequestLink
                            appearance={cardLinkAppearance}
                            to={portalLink}
                            isEmbeddedRoute={isEmbeddedRoute}
                        />
                    )}
                </Root>
            </AnalyticsContext>
        );
    }
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CategorySectionTitle = styled.h2`
    ${fonts.h500};
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0;

    /* Targeting the first child (icon) and give it a margin. */
    /* We do this so we don't affect the flex alignment. */
    > :first-child {
        margin-right: ${token('space.100', '8px')};
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CategoryItems = styled.ul`
    padding: 0;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    margin: 0 -${GROUP_BOX_PADDING_LEFT_RIGHT} ${token('space.200', '16px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CategoryItemContainer = styled.li`
    padding: 0;
    margin: 0;
    list-style: none;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Root = styled.div`
    flex: 1 1 auto;
`;
