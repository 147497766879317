import { isKoshEnabledForDefaultHelpCenter } from 'util/advanced-help-center';
import { isAdvancedCustomizationEnabled } from 'feature-flags';
import type { Route } from 'react-resource-router';
import { HelpCenter } from 'view/help-center';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { getFlexiHelpCenterRoute } from '../advanced-help-center';
import {
    brandingResource,
    featuredAndSortedPortalsResource,
    topicsResource,
} from '../advanced-help-center/resources/multi-help-centers';
import { searchComponentVirtualAgentResource } from '../advanced-help-center/resources/view-homepage';

export const getHelpCenterRoute = (): Route => {
    const name = 'help-center';
    const path = '/portals';
    const screenName = ScreenName.HELP_CENTER;

    if (isAdvancedCustomizationEnabled()) {
        return getFlexiHelpCenterRoute(name, path, screenName);
    }

    return createRouteWithLayout(
        {
            name,
            path,
            exact: false,
            component: HelpCenter,
            resources:
                __SERVER__ && isKoshEnabledForDefaultHelpCenter()
                    ? [
                          brandingResource,
                          featuredAndSortedPortalsResource,
                          topicsResource,
                          searchComponentVirtualAgentResource,
                      ]
                    : [searchComponentVirtualAgentResource],
        },
        screenName,
        {
            paperAppearance: 'none',
            hideSearchButton: true,
            fadeInSearchModal: false,
        }
    );
};
