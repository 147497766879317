import { isKoshEnabledForDefaultHelpCenter } from 'util/advanced-help-center/advanced-help-center';
import {
    fetchFeaturedAndSortedPortalsData,
    convertToFeaturedAndSortedPortalsResponse,
} from 'util/graphql-relay-connector/featured-and-sorted-portals';
import type { AjaxError } from 'rxjs';
import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';
import type { FeaturedAndSortedPortalsResponse } from 'rest/featured-and-sorted-portals';
import type {
    FetchFeaturedAndSortedPortalsAction,
    FetchFeaturedAndSortedPortalsSuccess,
    FetchFeaturedAndSortedPortalsFailure,
} from 'state/actions/featured-and-sorted-portals';
import {
    fetchFeaturedAndSortedPortalsSuccess,
    fetchFeaturedAndSortedPortalsFailure,
    FETCH_FEATURED_AND_SORTED_PORTALS_MODEL,
} from 'state/actions/featured-and-sorted-portals';
import type { HandleAjaxError } from 'state/actions/flags';
import { handleAjaxError } from 'state/actions/flags';

const emptyFeaturedAndSortedPortalsResponse: FeaturedAndSortedPortalsResponse = {
    portals: [],
    total: 0,
};

export const featuredAndSortedPortalsEpic: Epic<
    FetchFeaturedAndSortedPortalsAction,
    FetchFeaturedAndSortedPortalsSuccess | FetchFeaturedAndSortedPortalsFailure | HandleAjaxError
> = (action$) => {
    return action$.ofType(FETCH_FEATURED_AND_SORTED_PORTALS_MODEL).mergeMap((action) => {
        const { isPortalReorderingSelected, ...params } = action.payload.params;
        if (isKoshEnabledForDefaultHelpCenter()) {
            return Observable.from(
                fetchFeaturedAndSortedPortalsData(params)
                    .toPromise()
                    .then((response) => convertToFeaturedAndSortedPortalsResponse(response))
            )
                .flatMap((data) => {
                    return Observable.of(
                        fetchFeaturedAndSortedPortalsSuccess(
                            data || emptyFeaturedAndSortedPortalsResponse,
                            action.payload.params
                        )
                    );
                })
                .catch((error: AjaxError) => {
                    //if error while fetching sorted data, show error flag using ajax error payload
                    if (action.payload.params.sortBy) {
                        return concat(
                            Observable.of(fetchFeaturedAndSortedPortalsFailure(error, action.payload.params)),
                            Observable.of(handleAjaxError(error))
                        );
                    }

                    //for featured /un-featured portals, let component handle the error state
                    return Observable.of(fetchFeaturedAndSortedPortalsFailure(error, action.payload.params));
                });
        }
        return fetchModel({
            ...action.payload,
            params,
        })
            .filter((response) => !!response.featuredAndSortedPortals)
            .flatMap((response) => {
                return Observable.of(
                    fetchFeaturedAndSortedPortalsSuccess(
                        response.featuredAndSortedPortals || emptyFeaturedAndSortedPortalsResponse,
                        action.payload.params
                    )
                );
            })
            .catch((error: AjaxError) => {
                //if error while fetching sorted data, show error flag using ajax error payload
                if (action.payload.params.sortBy) {
                    return concat(
                        Observable.of(fetchFeaturedAndSortedPortalsFailure(error, action.payload.params)),
                        Observable.of(handleAjaxError(error))
                    );
                }

                //for featured /un-featured portals, let component handle the error state
                return Observable.of(fetchFeaturedAndSortedPortalsFailure(error, action.payload.params));
            });
    });
};
