import { defineMessages } from 'react-intl';

export default defineMessages({
    footer: {
        defaultMessage: 'Powered by {iconHtml} Jira Service Management',
        description: 'Message displayed in the webpage footer',
        id: 'sd.jsm.customerview.footer.powered.by.servicemanagement',
    },
    csmFooter: {
        defaultMessage: 'Powered by {iconHtml} Atlassian Customer Collection',
        description: 'Message displayed in the webpage footer',
        id: 'sd.jsm.customerview.footer.powered.by.atlassiancustomercollection.non-final',
    },
});
