import type { FC } from 'react';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import LockIcon from 'assets/images/lock.react.svg';
import { useIntl } from 'react-intl-next';
import { PaperContent } from 'view/layout/paper';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import { token } from '@atlaskit/tokens';
import { DocumentTitle } from '@atlassian/help-center-common-component/document-title';
import Redirect from '@atlassian/help-center-common-util/redirect';
import { getHelpCenterUrl } from '@atlassian/help-center-common-util/url';
import { ThemedLink } from '../common/themed-link';
import messages from './messages';

interface Props {
    isUserLoggedIn: boolean;
    userEmail: string;
}

const PortalAccessForbidden: FC<Props> = ({ isUserLoggedIn, userEmail }) => {
    const callToActionUrl = 'https://start.atlassian.com/';
    const { formatMessage } = useIntl();

    if (!isUserLoggedIn) {
        return <Redirect to={getHelpCenterUrl()} />;
    }

    return (
        <DocumentTitle title={formatMessage(messages.documentTitle)}>
            <PaperContent>
                <Container>
                    <IconContainer>
                        <LockIcon />
                    </IconContainer>
                    <Message>{formatMessage(messages.errorHeading)}</Message>
                    <Description>
                        <FormattedHTMLMessage
                            {...messages.errorDescription}
                            values={{
                                userEmail,
                            }}
                        />
                    </Description>
                    <AnchorContainer actionSubjectId="redirectToAtlassianStart" href={callToActionUrl}>
                        {formatMessage(messages.callToActionText)}
                    </AnchorContainer>
                </Container>
            </PaperContent>
        </DocumentTitle>
    );
};

export default PortalAccessForbidden;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin-top: ${token('space.600', '48px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const IconContainer = styled.div`
    width: ${grid.multiple(14).px};
    margin-bottom: ${token('space.200', '16px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Message = styled.div`
    ${fonts.h600};
    margin: 0 0 ${token('space.100', '8px')} 0;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Description = styled.p`
    ${fonts.regular};
    margin: 0 0 ${token('space.100', '8px')} 0;
    text-align: center;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const AnchorContainer = styled(ThemedLink)`
    text-align: center;
`;
