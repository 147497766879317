import * as React from 'react';
import type { SelectProps } from '@atlaskit/select';
import {
    ThemedSelect,
    ThemedAsyncSelect,
    ThemedAsyncCreatableSelect,
} from '@atlassian/help-center-common-component/themed-select';
import type { AddedAnalyticsProps } from '../../analytics/with-create-fire-ui-analytics';
import type { FieldOption } from '../with-controlled-value-single-select';
import { withControlledValueSingleSelect } from '../with-controlled-value-single-select';
import type { SelectFieldFormProps } from '../with-field-base';
import { withFieldBase } from '../with-field-base';

const FieldSingleSelect = withFieldBase(ThemedSelect);
const FieldAsyncSingleSelect = withFieldBase(ThemedAsyncSelect);
const FieldAsyncCreatableSingleSelect = withFieldBase(ThemedAsyncCreatableSelect);

interface FormAsyncCreatableSingleSelectBaseProps {
    showNewLabelForCreatedValues?: boolean;
}

class FormAsyncCreatableSingleSelectBase extends React.PureComponent<
    FormAsyncCreatableSingleSelectBaseProps &
        SelectFieldFormProps &
        SelectProps<FieldOption<string>> &
        AddedAnalyticsProps
> {
    onCreateOption = (input: string) => {
        const { onChange, showNewLabelForCreatedValues } = this.props;
        const newOption = {
            value: input,
            label: input,
            isNew: showNewLabelForCreatedValues,
        };

        onChange?.(newOption, { action: 'create-option', option: newOption });
    };

    render() {
        return <FieldAsyncCreatableSingleSelect {...this.props} onCreateOption={this.onCreateOption} />;
    }
}

const FormSingleSelect = withControlledValueSingleSelect<string>(FieldSingleSelect);
const FormAsyncSingleSelect = withControlledValueSingleSelect<string>(FieldAsyncSingleSelect);
const FormAsyncCreatableSingleSelect = withControlledValueSingleSelect<string>(FormAsyncCreatableSingleSelectBase);

export { FormSingleSelect, FormAsyncSingleSelect, FormAsyncCreatableSingleSelect, FieldSingleSelect };
