import { defineMessages } from 'react-intl';

export default defineMessages({
    learnByDoingDesc: {
        id: 'help-center.learn-by-doing.create-request.description',
        defaultMessage: 'Welcome to the portal! This is where customers can raise a request for help',
        description: 'Description for Learn By Doing create Request component',
    },
    chooseRequestTypeHeading: {
        id: 'help-center.learn-by-doing.choose-request-type.heading',
        defaultMessage: 'Choose a request type',
        description: 'Heading for choosing a request type in Learn By Doing component',
    },
    chooseRequestTypeDesc: {
        id: 'help-center.learn-by-doing.choose-request-type.description',
        defaultMessage: 'Request types represent what your team can help customers with.',
        description: 'Description about request types',
    },
    sendExampleRequestHeading: {
        id: 'help-center.learn-by-doing.send-example-request.heading',
        defaultMessage: 'Send an example request',
        description: 'Heading for sending an example request in Learn By Doing component',
    },
    sendExampleRequestDesc: {
        id: 'help-center.learn-by-doing.send-example-request.description',
        defaultMessage: 'Add more detail with the request form, then send it off!',
        description: 'Description for sending a request',
    },
    requestCreatedHeading: {
        id: 'help-center.learn-by-doing.request-created.heading',
        defaultMessage: 'See how it looks for your team',
        description: 'Heading after request is created in Learn By Doing component',
    },
    requestCreatedDesc: {
        id: 'help-center.learn-by-doing.request-created.description',
        defaultMessage: 'Your agents can work on requests in your service project.',
        description: 'Description after request is created',
    },
    queuesRedirectLabel: {
        id: 'help-center.learn-by-doing.queues-redirect.label',
        defaultMessage: 'Go to project',
        description: 'Title for queues redirect button',
    },
});
