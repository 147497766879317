import { updateUserInfo } from 'util/analytics/client';
import { isNil } from 'lodash';
import { getMetaFromHTML, setHTMLMeta } from '@atlassian/help-center-common-util/meta';

// We are adding these meta tags so that JS API AP.user.getCurrentUser() can return account details after customer
// logs-in as currently after customer login there is no reloading of page so these meta tags are not gets updated.
// Additionally, if the user changes in another tab, this will update the meta tags.
export const setAccountMetaTag = (isAtlassianManaged: boolean, accountId?: string) => {
    if (!accountId) {
        return;
    }

    const accountTypeMeta = getMetaFromHTML('ajs-account-type');
    const accountIdMeta = getMetaFromHTML('ajs-atlassian-account-id');
    if (!isNil(accountTypeMeta) && !isNil(accountIdMeta) && accountIdMeta === accountId) {
        return;
    }
    setHTMLMeta('ajs-atlassian-account-id', accountId);
    setHTMLMeta('account-id', accountId);
    if (isAtlassianManaged) {
        setHTMLMeta('ajs-account-type', 'atlassian');
    } else {
        setHTMLMeta('ajs-account-type', 'customer');
    }
    updateUserInfo(accountId);
};
