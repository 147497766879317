import type { FetchError } from 'util/fetch';
import { isUpdateAnalyticsFailureEventWithError } from 'feature-flags';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ApolloErrorResponse, ErrorResponse } from 'epics/model/types';

export const logErrorToFailureEvent = (
    analyticsFailureEvent: UIAnalyticsEvent,
    error: Error | ApolloErrorResponse | ErrorResponse
) => {
    if (!isUpdateAnalyticsFailureEventWithError()) {
        return analyticsFailureEvent;
    }
    analyticsFailureEvent.update((payload: UIAnalyticsEvent) => ({
        ...payload,
        errorMessage:
            (error as Error).message ||
            (error as ApolloErrorResponse).errorMessage ||
            (error as ErrorResponse).errorMessages?.join(','),
        status: (error as FetchError).status || (error as ApolloErrorResponse).statusCode,
    }));
    return analyticsFailureEvent;
};
