import { defineMessages } from 'react-intl';

export default defineMessages({
    sortByFilterLabel: {
        defaultMessage: 'Sort by',
        description: 'Label for the sort by filter',
        id: 'cv.portal.reordering.experience.sortBy.filter.label',
    },
    popularityOption: {
        defaultMessage: 'Popularity',
        description: 'Label for the sort by popularity option',
        id: 'cv.portal.reordering.experience.sortBy.filter.popularity.label',
    },
    alphabeticOption: {
        defaultMessage: 'Name: A to Z',
        description: 'Label for the sort by alphabetical option',
        id: 'cv.portal.reordering.experience.sortBy.filter.alphabetical.label',
    },
});
