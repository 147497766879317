import { contextPath } from 'util/history';
import { post } from 'epics/ajax';

const getResendVerifyEmailRequestBody = (email: string, portalId: number | null) => ({
    email,
    verificationContext: {
        portalId,
    },
});

export const resendVerifyEmail = (email: string, portalId: number | null) => {
    const uri = `${contextPath}/rest/servicedesk/1/customer/pages/user/resend-verification-email`;

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    const body = getResendVerifyEmailRequestBody(email, portalId);

    return post(uri, body, headers);
};
