import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'view/error-boundary/messages';
import { Root, Container, MessageContainer, ErrorMessage, HelpText, PageErrorIcon } from 'view/error-boundary/styled';
import { Footer } from 'view/layout/footer';

interface GenericErrorProps {
    title?: React.ReactElement;
    message?: React.ReactElement;
    hideFooter?: boolean;
}

const GenericErrorPage: React.FunctionComponent<GenericErrorProps> = ({ title, message, hideFooter }) => {
    return (
        <Root>
            <Container>
                <PageErrorIcon />

                <MessageContainer>
                    <ErrorMessage>{title ? title : <FormattedMessage {...messages.errorTitle} />}</ErrorMessage>

                    <HelpText>{message ? message : <FormattedMessage {...messages.errorMessage} />}</HelpText>
                </MessageContainer>
            </Container>
            {!hideFooter && <Footer />}
        </Root>
    );
};

export default GenericErrorPage;
