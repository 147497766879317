import { once } from 'lodash';
import { AssetFieldValueElement } from './asset-field-value';

// This is where all of our custom Web Components should be defined.
export const install = once(() => {
    // asset-field-value is rendered on Request Details; it displays the names of assets linked to the request
    if (window.customElements) {
        customElements.define('asset-field-value', AssetFieldValueElement);
    }
});
