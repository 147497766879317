import { logErrorToFailureEvent } from 'util/log-error-to-failure-event';
import type { ActionsObservable } from 'redux-observable';
import type { AjaxError } from 'rxjs';

import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';

import type { PortalAnnouncementsUpdateResponse } from 'rest/portal';
import { updatePortalAnnouncements } from 'rest/portal';
import type { HandleAjaxError } from 'state/actions/flags';
import { handleAjaxError } from 'state/actions/flags';
import type {
    UpdatePortalAnnouncementModel,
    UpdatePortalAnnouncementSuccess,
    UpdatePortalAnnouncementFailure,
} from 'state/actions/portal';
import {
    UPDATE_PORTAL_ANNOUNCEMENT_MODEL,
    updatePortalAnnouncementSuccess,
    updatePortalAnnouncementFailure,
} from 'state/actions/portal';

import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { isNetworkOrClientErrorCode } from '@atlassian/help-center-common-util/error-codes';
import { getErrorData } from '../../util/analytics/listener';

export const updatePortalAnnouncementsEpic: Epic<
    UpdatePortalAnnouncementModel | UpdatePortalAnnouncementSuccess | UpdatePortalAnnouncementFailure | HandleAjaxError
> = (action$: ActionsObservable<UpdatePortalAnnouncementModel>) => {
    return action$.ofType(UPDATE_PORTAL_ANNOUNCEMENT_MODEL).mergeMap((action: UpdatePortalAnnouncementModel) => {
        const { meta, portalId } = action;

        return updatePortalAnnouncements(action.payload, portalId)
            .flatMap((response: PortalAnnouncementsUpdateResponse) => {
                if (meta?.analyticsSuccessEvent) {
                    sendEvent(meta.analyticsSuccessEvent);
                }
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                meta?.updateSinglePortalAnnouncementSideBarExperience.success();

                return concat(Observable.of(updatePortalAnnouncementSuccess(response)));
            })
            .catch((error: AjaxError) => {
                if (isNetworkOrClientErrorCode(error.status)) {
                    // Filter out client errors
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    meta?.updateSinglePortalAnnouncementSideBarExperience.success();
                } else {
                    if (meta?.analyticsFailureEvent) {
                        sendEvent(logErrorToFailureEvent(meta.analyticsFailureEvent, error));
                    }
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    meta?.updateSinglePortalAnnouncementSideBarExperience.failure({
                        metadata: {
                            error: getErrorData(error),
                        },
                    });
                }

                return concat(
                    Observable.of(updatePortalAnnouncementFailure(error)),
                    Observable.of(handleAjaxError(error))
                );
            });
    });
};
