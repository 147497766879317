import * as React from 'react';
import { useIntl } from 'react-intl-next';
import type { Category } from 'rest/category';
import type { SelectValues } from '@atlassian/paginated-picker';
import { SimplePicker } from '../picker';
import messages from './messages';
import type { CategoryFilterProps } from './types';

const CategoryFilter: React.FunctionComponent<CategoryFilterProps> = ({
    portalId,
    fetchCategories,
    categories,
    categoryIds,
    onChange,
    projectId,
}) => {
    const { formatMessage } = useIntl();
    const [values, _] = React.useState(categoryIds ?? []);

    React.useEffect(() => {
        if (!projectId || !portalId) {
            return;
        }

        fetchCategories(projectId, portalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, portalId]);

    const options: SelectValues = React.useMemo(() => {
        return categories.map((category: Category) => ({
            optionType: 'option',
            value: category.id,
            label: category.name,
        }));
    }, [categories]);

    const preSelectedOptions: SelectValues = React.useMemo(() => {
        const preselectedCategories = categories.filter((category: Category) =>
            values.find((id) => id === category.id)
        );
        return preselectedCategories.map((category: Category) => ({
            optionType: 'option',
            value: category.id,
            label: category.name,
        }));
    }, [categories, values]);

    return categories.length > 1 ? (
        <SimplePicker
            fieldId="category_filter"
            fieldLabel={formatMessage(messages.categoryFilter)}
            testId="categoryFilter"
            options={options}
            preSelectedOptions={preSelectedOptions}
            onChange={onChange}
        />
    ) : null;
};

export default CategoryFilter;
