import { initUFO } from 'util/analytics/react-ufo';
import { embedFFDataToUFO } from './util/analytics/ufo-feature-flags';
import { initialiseFeatureGates } from './util/feature-manager';
import type { WindowFlagVars } from './util/feature-manager';
import '@atlaskit/css-reset';
import { sentryQueue } from './util/sentry-queue';

const globalVar = (typeof window !== 'undefined' ? window : globalThis) as unknown as WindowFlagVars;

document.addEventListener('DOMContentLoaded', () => {
    const element = document.getElementById('page');
    element && element.parentElement && element.parentElement.removeChild(element);
    initialiseFeatureGates(globalVar)
        .catch((err) => sentryQueue.pushError(err))
        .finally(() => {
            if (!__SERVER__) {
                try {
                    !__SERVER__ && initUFO();
                } catch (err) {
                    sentryQueue.pushError(err);
                }
                embedFFDataToUFO();
            }
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, global-require
            const { bootstrap } = require('./bootstrap');
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            bootstrap();
        });
});
