import * as React from 'react';
import { reportError } from 'util/sentry';
import { isOldSafari } from 'util/unsupported-browsers';
import type { FetchError } from '@atlassian/help-center-common-util/fetch';

interface UnSupportedBrowserErrorBoundaryProps {
    children: React.ReactNode;
    onError?: (error: Error | FetchError) => void;
    renderError?: () => React.ReactNode;
}

interface State {
    error: Error | undefined;
}

const isUnSupportedBrowser = () => {
    return isOldSafari();
};

export class UnSupportedBrowserErrorBoundary extends React.Component<UnSupportedBrowserErrorBoundaryProps, State> {
    state = {
        error: undefined,
    };

    componentDidCatch(error: Error) {
        const { onError } = this.props;
        if (isUnSupportedBrowser()) {
            /**
             * Report error to sentry and don't re-throw the error
             */
            reportError(error);

            if (onError) {
                onError(error);
            }
        } else {
            throw error;
        }
    }

    static getDerivedStateFromError(error: Error) {
        return {
            error,
        };
    }

    render() {
        const { children, renderError } = this.props;
        const { error } = this.state;

        if (error) {
            return renderError ? renderError() : null;
        }

        return children;
    }
}

export default UnSupportedBrowserErrorBoundary;
