import { useContext } from 'react';
import { has } from 'lodash';
import { FeatureFlagsContext } from './provider';
import type { SupportedFlagTypes } from 'util/feature-manager/types';

interface Props {
    children: JSX.Element;
    showIf?: 'enabled' | 'disabled';
    name: string;
    defaultValue: SupportedFlagTypes;
}

const FeatureFlag = ({ children, name, defaultValue, showIf = 'enabled' }: Props): JSX.Element | null => {
    const flags = useContext(FeatureFlagsContext);
    let value = defaultValue;

    if (has(flags, name)) {
        value = !!flags[name];
    }

    const shouldShow = showIf === 'enabled' ? value : !value;
    return shouldShow ? children : null;
};

export default FeatureFlag;
