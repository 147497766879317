import * as React from 'react';
import type { SelectProps, Option } from '@atlaskit/select';
import { FieldBase } from '@atlassian/help-center-common-component/fields/field-base';

export interface SelectFieldFormProps {
    id: string;
    errorMessage?: string;
    label: string;
    required?: boolean;
    description?: string;
    isClearable?: boolean;
    value?: Option<string> | null;
}

function withFieldBase<V extends Option<string>, T extends SelectProps<V, K>, K extends boolean>(
    SelectComponent: React.ComponentType<T>
) {
    const wrappedComponent: React.FunctionComponent<SelectFieldFormProps & T> = (
        props: SelectFieldFormProps & SelectProps<V, K>
    ) => {
        const { label, required, errorMessage, description, id, ...otherProps } = props;

        return (
            <FieldBase
                htmlFor={id}
                label={label}
                required={required}
                errorMessage={errorMessage}
                description={description}
            >
                <SelectComponent
                    // Bug introduced with spread operator. Since we have a complex case here
                    // we can't just cast it properly.
                    // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    {...(otherProps as any)}
                    inputId={id}
                    validationState={!!errorMessage ? 'error' : 'default'}
                />
            </FieldBase>
        );
    };

    return wrappedComponent;
}

export default withFieldBase;
