import memoizeOne from 'memoize-one';

const SUMMARY_TYPE = 'summary';
const SELECT_CF_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:select';

export class RequestCreateViewConfiguration {
    /**
     * memoizeOne is used to avoid recreating the config objects.
     */
    getSupportedFieldsConfiguration = memoizeOne(
        // TODO: Replace this type with the real one once UIM on platfrom is ready.
        (): Record<
            string,
            {
                publicShapeValidators: Record<string, (value: unknown) => boolean>;
                publicToInternalTransformers: Record<string, (value: unknown) => object>;
            }
        > => {
            // TODO: replace with real common validtors from @tlassian/ui-modification-core once ready.
            const commonFieldValidators = {
                fieldName: (value: unknown) => typeof value === 'string',
                isVisible: (value: unknown) => typeof value === 'boolean',
                description: (value: unknown) => typeof value === 'string',
                isReadOnly: (value: unknown) => typeof value === 'boolean',
                isRequired: (value: unknown) => typeof value === 'boolean',
            };

            return {
                summary: {
                    // It contains also supported field properties.
                    // Validator MUST be added here to make given property supported.
                    publicShapeValidators: {
                        ...commonFieldValidators,
                        value: (value: unknown) => typeof value === 'string' && value.length < 256,
                    },
                    publicToInternalTransformers: {},
                },
                [SELECT_CF_TYPE]: {
                    publicShapeValidators: {
                        ...commonFieldValidators,
                        value: () => true,
                    },
                    publicToInternalTransformers: {},
                },
            };
        }
    );

    getSupportedFieldTypes = memoizeOne(() => {
        const supportedFieldTypes: string[] = [SUMMARY_TYPE, SELECT_CF_TYPE];

        return supportedFieldTypes;
    });
}
