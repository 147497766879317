import { defineMessages } from 'react-intl-next';

export default defineMessages({
    helpCenterDetailsPageTitle: {
        defaultMessage: 'Details',
        description: 'Help center edit details page title',
        id: 'help.center.edit.details.page.title',
    },
    helpCenterDetailsPageDescription: {
        defaultMessage:
            'Customize the name and URL of your help center to reflect your brand identity and effectively convey its purpose to your customers.',
        description: 'Help center edit details page description',
        id: 'help.center.edit.details.page.description',
    },
    defaultHelpCenterDetailsPageDescription: {
        defaultMessage:
            'Customize the name of your default help center to reflect your brand identity and effectively convey its purpose to your customers.',
        description: 'Default help center edit details page description',
        id: 'help.center.default.help.center.description',
    },
    helpCenterCreateFormTitle: {
        defaultMessage: 'Create help center',
        description: 'Help center create form title',
        id: 'help.center.create.form.title',
    },
    helpCenterCreateFormDescription: {
        defaultMessage:
            'Every new help center displays all open portals on your site by default. You can edit the home page layout to show or hide portals based on the audience.',
        description: 'Help center create form description',
        id: 'help.center.create.form.description',
    },
    helpCenterEditFormTitle: {
        defaultMessage: 'Edit help center details',
        description: 'Help center edit form title',
        id: 'help.center.edit.form.title',
    },
    helpCenterAddErrorTitle: {
        defaultMessage: `We couldn’t create your help center`,
        description: 'Title message for add help center error',
        id: 'help.center.add.error.title',
    },
    helpCenterAddSuccessTitle: {
        defaultMessage: `'{value}' created`,
        description: 'Title message for add help center success',
        id: 'help.center.add.success.title',
    },
    helpCenterAddSuccessDesc: {
        defaultMessage: 'Go to help center',
        description: 'Description message for add help center success',
        id: 'help.center.add.success.description',
    },
    helpCenterUpdateErrorTitle: {
        defaultMessage: 'We couldn’t update your help center',
        description: 'Title message for update help center error',
        id: 'help.center.update.error.title',
    },
    helpCenterUpdateSuccessTitle: {
        defaultMessage: `'{value}' updated`,
        description: 'Title message for add help center success',
        id: 'help.center.update.success.title',
    },
    helpCenterGenericErrorDesc: {
        defaultMessage: 'Check your connection and try again.',
        description: 'Generic description message for help center error',
        id: 'help.center.update.error.description.generic',
    },
    helpCenterErrorDesc: {
        defaultMessage: '{value}',
        description: 'Description message for help center error',
        id: 'help.center.update.error.description',
    },
    helpCenterHardLimitErrorTitle: {
        defaultMessage: 'You’ve already created 300 help centers',
        description: 'Message title for help center hard limit reached error',
        id: 'help.center.create.hard.limit.error.title',
    },
    helpCenterHardLimitErrorDescription: {
        defaultMessage: 'Delete a help center to create another one.',
        description: 'Message description for help center hard limit reached error',
        id: 'help.center.create.hard.limit.error.description',
    },
    helpCenterNameRequiredError: {
        defaultMessage: 'Enter a name for your help center.',
        description: 'Validation error message when help center name is empty',
        id: 'help.center.name.required.validation.error',
    },
    helpCenterNameCharacterLimitError: {
        defaultMessage: 'Enter a help center name with 30 characters or less.',
        description: 'Validation error message when help center name is greater than required limit',
        id: 'help.center.name.character.limit.validation.error',
    },
    helpCenterSlugRequiredError: {
        defaultMessage: 'Add a slug.',
        description: 'Validation error message when help center slug is empty',
        id: 'help.center.slug.required.validation.error',
    },
    helpCenterSlugCharacterLimitError: {
        defaultMessage: 'Enter a slug with 30 characters or less.',
        description: 'Validation error message when help center slug is greater than required limit',
        id: 'help.center.slug.character.limit.validation.error',
    },
    helpCenterSlugSpecialCharacterError: {
        defaultMessage: `Add a slug that doesn’t contain special characters other than hyphen (-) and underscore (_).`,
        description: 'Validation error message when help center slug contains special characters',
        id: 'help.center.slug.special.character.validation.error',
    },
    helpCenterAlreadyExistedSlugErrorDescription: {
        defaultMessage: 'A help center with this slug already exists on your site. Try again with a different slug.',
        description: 'Error message when help center slug already exists',
        id: 'help.center.already.existed.slug.error.description',
    },
    helpCenterReservedSlugErrorDescription: {
        defaultMessage: 'The slug you’ve entered contains a reserved term. Try again with a different slug.',
        description: 'Error message when entered slug contains reserved term',
        id: 'help.center.reserved.slug.error.description',
    },
    helpCenterRequiredFieldsWarning: {
        defaultMessage: 'Required fields are marked with an asterisk',
        description: 'Required warning message for update help center fields',
        id: 'help.center.update.form.required.warning',
    },
});
