import { di } from 'react-magnetic-di';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import { commitLocalUpdate, ConnectionHandler } from 'relay-runtime';
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import { v4 } from 'uuid';
import type { AuthorRole } from '../../common/types';

interface AddMessageToConnectionArgs {
    environment: RelayModernEnvironment;
    message: string;
    connectionId: string;
    authorType: AuthorRole;
}
export const addMessageToConnection = ({
    environment,
    connectionId,
    message,
    authorType,
}: AddMessageToConnectionArgs) => {
    di(v4);
    commitLocalUpdate(environment, (store) => {
        const connection = store.get(connectionId);

        if (!connection) return;

        const nodeId = `client:jsmChat:conversation:${v4()}:customMessage:node`;
        const node = store.create(nodeId, 'JsmChatCreateWebConversationMessage');

        node.setValue(nodeId, 'id');
        node.setValue(authorType, 'authorType');
        if (message) node.setValue(message, 'content');
        node.setValue('ADF', 'contentType');
        node.setValue(null, 'appendices');

        const newEdge = ConnectionHandler.createEdge(store, connection, node, 'JsmChatMessageEdge');
        ConnectionHandler.insertEdgeAfter(connection, newEdge);
    });
};

interface AddNodeToConnectionArgs<T> {
    connectionId: string;
    recordId: string;
    store: RecordSourceSelectorProxy<T>;
    edgeName: string;
}

export const addNodeToConnection = <T,>({ store, recordId, connectionId, edgeName }: AddNodeToConnectionArgs<T>) => {
    const connection = store.get(connectionId);
    if (!connection) return;
    const node = store.get(recordId);
    if (!node) return;
    const newEdge = ConnectionHandler.createEdge(store, connection, node, edgeName);
    ConnectionHandler.insertEdgeAfter(connection, newEdge);
};
