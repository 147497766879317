import { defineMessages } from 'react-intl';

export default defineMessages({
    loading: {
        id: 'asset.tracking.field.loading.asset',
        defaultMessage: 'Loading asset...',
        description: 'Displayed when asset label is being loaded from saffron (asset microservice)',
    },
    unknown: {
        id: 'asset.tracking.field.unknown.asset',
        defaultMessage: 'Unknown asset',
        description: 'Displayed when a value for an asset field is not known (eg: it has been deleted)',
    },
});
