import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { GenericErrorPage } from 'view/error-boundary/generic-error-page';
import messages from './messages';

export const Error = () => {
    return (
        <GenericErrorPage
            title={<FormattedMessage {...messages.errorTitle} />}
            message={<FormattedHTMLMessage {...messages.errorDescription} />}
            hideFooter
        />
    );
};
