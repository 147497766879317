import * as React from 'react';
import { useCallback } from 'react';

import { getBaseName } from 'util/history';
import type { History } from 'history';
import { createLocation } from 'history';
import type { Location } from 'react-resource-router';
import { Link, RouterSubscriber } from 'react-resource-router';

export const normalizeToLocation = (to: string, currentLocation: Location) => {
    // @ts-ignore the location provided by RRR is compatible with history location
    return createLocation(to, null, undefined, currentLocation);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isModifiedEvent = (event: { [key: string]: any }) =>
    !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export type Props = Omit<React.ComponentPropsWithRef<typeof Link>, 'to'> & {
    to: string;
};

export type PropsWithRouter = Props & {
    history: History;
    location: Location;
};

/**
 * In server side rendering we use memory history, which doesn't have the base name. hence append basename manually
 */
function getHref(history: History, location: Location): string {
    return __SERVER__ ? `${getBaseName()}${history.createHref(location)}` : history.createHref(location);
}

/**
 * React resource router doesn't support baseUrl if the "to" property is a string.
 * We have to introduce this wrapper to workaround it otherwise the migration's surface area become too large.
 * Moving forward we should use "route" instead of string for the link component since it is better supported.
 *
 *  @deprecated this component shouldn't be used directly.
 */
const BaseUrlRespectLink: React.FC<PropsWithRouter> = (props) => {
    const { to, onClick, history, location, ...rest } = props;

    const toAsLocation = normalizeToLocation(to, location);
    const href = toAsLocation ? getHref(history, toAsLocation) : '';
    const wrappedOnClick = useCallback((e: React.MouseEvent | React.KeyboardEvent) => {
        try {
            onClick && onClick(e);
        } catch (ex) {
            e.preventDefault();
            throw ex;
        }
        if (
            !e.defaultPrevented && // onClick prevented default
            ((e as React.MouseEvent).button === 0 || (e as React.KeyboardEvent).keyCode === 13) && // ignore everything but left clicks or enter
            (!props.target || props.target === '_self') && // let browser handle "target=_blank" etc.
            !isModifiedEvent(e) // ignore clicks with modifier keys
        ) {
            e.preventDefault();
            const method = props.replace ? history.replace : history.push;

            method(toAsLocation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Link href={href} {...rest} onClick={wrappedOnClick} />;
};

const BaseUrlRespectLinkWithRouter: React.FC<Props> = (props) => {
    return (
        <RouterSubscriber>
            {(
                // @ts-ignore access private `history` store property
                { location, history }
            ) => (
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                <BaseUrlRespectLink location={location} history={history} {...props} />
            )}
        </RouterSubscriber>
    );
};

export default BaseUrlRespectLinkWithRouter;
