import { defineMessages } from 'react-intl';

export default defineMessages({
    login: {
        defaultMessage: 'Log in',
        description: 'Text message for header in Customer Portal when a user is not logged in',
        id: 'cv.login.title',
    },
    helpCenter: {
        defaultMessage: 'Help Center',
        description: 'Text message for header in Customer Portal when a user is logged in',
        id: 'cv.help.center.title',
    },
});
