import { getCloudId, getHelpCenterAri } from 'util/meta';
import environment from 'util/relay/environment';
import { AjaxError } from 'rxjs';
import { fetchQuery, graphql } from 'relay-runtime';
import type {
    HelpCenterPortalFilter,
    HelpCenterPortalsSortOrder,
    featuredAndSortedPortalsQuery,
    featuredAndSortedPortalsQuery$data,
} from './__generated__/featuredAndSortedPortalsQuery.graphql';
import type { FeaturedAndSortedPortalsResponse, SortBy } from 'rest/featured-and-sorted-portals/types';
interface Params {
    sortBy?: SortBy;
    exploreAll?: boolean;
}
export const fetchFeaturedAndSortedPortalsData = (params: Params) => {
    return fetchQuery<featuredAndSortedPortalsQuery>(
        environment,
        graphql`
            query featuredAndSortedPortalsQuery(
                $cloudId: ID!
                $helpCenterAri: ID!
                $sortOrder: HelpCenterPortalsSortOrder
                $portalsFilter: HelpCenterPortalFilter
            ) {
                helpCenter(cloudId: $cloudId) {
                    helpCenterById(helpCenterAri: $helpCenterAri) @optIn(to: ["HelpCenterAggBeta"]) {
                        ... on HelpCenter {
                            __typename
                            portals(sortOrder: $sortOrder, portalsFilter: $portalsFilter) {
                                sortOrder
                                portalsList {
                                    id
                                    key
                                    name
                                    description
                                    logoUrl
                                    portalBaseUrl
                                    isFeatured
                                    isHidden
                                    rank
                                }
                            }
                        }
                        ... on QueryError {
                            __typename
                            message
                            extensions {
                                statusCode
                            }
                        }
                    }
                }
            }
        `,
        {
            cloudId: getCloudId(),
            helpCenterAri: getHelpCenterAri(),
            sortOrder: getSortIngParams(params.sortBy),
            portalsFilter: getFilterParams(params.exploreAll),
        }
    );
};
const getFilterParams = (exploreAll: boolean | undefined) => {
    if (exploreAll) {
        return undefined;
    }
    return { typeFilter: ['VISIBLE'] } as HelpCenterPortalFilter;
};

const getSortIngParams = (sortBy: SortBy | undefined) => {
    if (sortBy === 'ALPHABETICALLY') {
        return 'NAME_ASCENDING';
    }
    if (sortBy === 'POPULARITY') {
        return 'POPULARITY';
    }
    return undefined;
};
const getSortIngValues = (sortBy: HelpCenterPortalsSortOrder | null | undefined) => {
    if (sortBy === 'NAME_ASCENDING') {
        return 'ALPHABETICALLY';
    }
    if (sortBy === 'POPULARITY') {
        return 'POPULARITY';
    }
    return undefined;
};

export const convertToFeaturedAndSortedPortalsResponse = (
    response: featuredAndSortedPortalsQuery$data | undefined
): FeaturedAndSortedPortalsResponse | undefined => {
    if (response === undefined) {
        return undefined;
    }
    const data = response?.helpCenter?.helpCenterById;
    if (data?.__typename === 'QueryError') {
        const extensions = data.extensions;
        const message = data.message;
        if (extensions && Array.isArray(extensions) && extensions.length > 0 && message) {
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const { statusCode } = extensions[0];
            const xhr = {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                status: statusCode,
                responseType: 'json',
                response: data,
            } as unknown as XMLHttpRequest;
            throw new AjaxError(`${message} with status ${String(statusCode)}`, xhr, {});
        }
        throw new Error(message || '');
    }

    if (data?.__typename === 'HelpCenter') {
        const portals = data.portals;
        const { portalsList } = portals || { portalsList: [] };

        return {
            sortBy: getSortIngValues(portals?.sortOrder),
            // Backend will return all the portals there is no pagination
            total: portalsList?.length,
            // This FF has been rolled out so this is always true
            isPortalHidingEnabled: true,
            portals:
                portalsList?.map((portal) => ({
                    // right now id is number will have to verify while backend integration
                    id: parseInt(portal.id, 10),
                    key: portal.key || '',
                    name: portal.name || '',
                    description: portal.description || '',
                    logoUrl: portal.logoUrl || undefined,
                    rank: portal.rank || -1,
                    portalBaseUrl: portal.portalBaseUrl,
                    isFeatured: !!portal.isFeatured,
                    hiddenFromHomeScreen: !!portal.isHidden,
                    isPortalNotVisibleOnHelpCentre: false,
                })) || [],
        };
    }
    return undefined;
};
