import { defineMessages } from 'react-intl';

export default defineMessages({
    bannerDescription: {
        defaultMessage:
            'Can’t find something? Some apps are blocked by your admin in this project through a data security policy.',
        description: 'The description of blocked apps banner',
        id: 'sd.portal.blocked.app.banner.description',
    },
    doNotShow: {
        defaultMessage: "Don't show again",
        description:
            'Do not show again link. When the user clicks on this, he will not see the blocked apps banner again',
        id: 'sd.portal.blocked.app.banner.doNotShow',
    },
    learnMore: {
        defaultMessage: 'View blocked apps',
        description: 'This is a link that the users can click to view the blocked apps',
        id: 'sd.portal.blocked.app.banner.learnMore',
    },
});
