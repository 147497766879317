import { last } from 'lodash';
import type { StoreActionApi } from 'react-sweet-state';
import type { HelpseekerMessage } from '../../../common/types';
import { sendTrackEvent } from '../../../common/utils';
import type { ConversationStateContainerProps, State } from '../types';
import { sendMessageErrorHandler } from './send-message-error-handler';

export const sendHelpseekerMessage =
    (message: HelpseekerMessage) =>
    (
        { getState, setState, dispatch }: StoreActionApi<State>,
        {
            conversationProvider,
            handleHandOff,
        }: Pick<ConversationStateContainerProps, 'conversationProvider' | 'handleHandOff'>
    ) => {
        const { conversationId, portalId, projectId, contextId, handleEscalateFromVirtualAgent, abortController } =
            getState();

        if (!conversationId || !abortController) {
            dispatch(sendMessageErrorHandler());
            return;
        }

        conversationProvider
            .sendMessage(message, abortController.signal)
            .then(async (response) => {
                // https://data-portal.internal.atlassian.com/analytics/registry/65969
                sendTrackEvent({
                    action: 'sent',
                    actionSubject: 'conversationMessage',
                    actionSubjectId: conversationId,
                    attributes: {
                        conversationId,
                        contextId,
                        portalId: portalId?.toString(),
                        projectId: projectId?.toString(),
                    },
                });

                if (
                    handleEscalateFromVirtualAgent &&
                    response.length &&
                    response[response.length - 1].conversationState === 'escalated'
                ) {
                    handleEscalateFromVirtualAgent();
                    return;
                }

                if (last(response)?.shouldHandoff) {
                    const { messages } = getState();
                    if (handleHandOff) {
                        await handleHandOff(messages, conversationId, contextId);
                    }
                    return;
                }

                for (let i = 0; i < response.length; i++) {
                    setTimeout(() => {
                        const { messages: newMessages } = getState();
                        setState({
                            messages: newMessages.concat(response[i]),
                        });
                    }, 500 * i); // 500ms delay between each message
                }
            })
            .catch(() => dispatch(sendMessageErrorHandler()))
            .finally(() => setState({ isLoading: false }));
    };
