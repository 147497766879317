import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as fonts from 'view/styles/fonts';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { GROUP_BOX_PADDING_LEFT_RIGHT } from '@atlassian/help-center-common-component/group-box';
import { LoadingContainer } from '@atlassian/help-center-common-component/loading-container';
import { RaiseRequestLink } from '@atlassian/help-center-common-component/raise-request-link';
import { ThemedLink } from '@atlassian/help-center-common-component/themed-link';
import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import { ArticleListItem } from '../article-list-item';
import messages from './messages';
import type { Article } from 'state/persisted/kb-article/types';

interface ArticleListProps {
    articles: Article[] | undefined;
    portalName: string;
    portalId: number;
    portalLink: string;
    categoryId: string;
    showRequestCreateButton: boolean;
    isLoading: boolean;
}

const renderSectionList = (
    portalId: number,
    categoryId: string,
    articles: Article[],
    opts: { isFeatured: boolean; showHeading: boolean }
) => (
    <section>
        {opts.showHeading && (
            <SectionHeading>
                <FormattedMessage
                    {...(opts.isFeatured ? messages.featuredArticlesHeading : messages.moreArticlesHeading)}
                />
            </SectionHeading>
        )}

        <ArticleOrderedList>
            {articles.map((article: Article) => {
                const { title, summaryExcerpt, id: articleId } = article;

                return (
                    <ArticleListItem
                        portalId={portalId}
                        categoryId={categoryId}
                        articleId={articleId}
                        key={articleId}
                        title={title}
                        summaryExcerpt={summaryExcerpt}
                        isFeatured={opts.isFeatured}
                    />
                );
            })}
        </ArticleOrderedList>
    </section>
);

/**
 * ArticleList comes with forced top margin because of the featured vs. non featured articles
 * groupings. Take this into consideration when using in places no under knowledge category page.
 */
const ArticleList: React.FunctionComponent<ArticleListProps> = ({
    articles,
    portalName,
    portalId,
    portalLink,
    categoryId,
    showRequestCreateButton,
    isLoading,
}) => {
    const featuredArticles = articles ? articles.filter((article) => article.isFeatured) : [];
    const regularArticles = articles ? articles.filter((article) => !article.isFeatured) : [];
    const hasRegularArticles = regularArticles.length > 0;
    const hasFeaturedArticles = featuredArticles.length > 0;
    const hasAnyArticles = hasRegularArticles || hasFeaturedArticles;
    const containerHasExtraMargin = (hasRegularArticles && hasFeaturedArticles) || !hasAnyArticles;

    return (
        <AnalyticsContext
            data={{
                attributes: {
                    numberOfArticles: articles && articles.length,
                },
            }}
        >
            <ArticleListContainer marginTop={containerHasExtraMargin}>
                <LoadingContainer isLoading={isLoading} loadingPosition="fill-container">
                    <FireScreenEventOnMount />

                    {hasFeaturedArticles &&
                        renderSectionList(portalId, categoryId, featuredArticles, {
                            isFeatured: true,
                            showHeading: hasRegularArticles,
                        })}

                    {hasRegularArticles &&
                        renderSectionList(portalId, categoryId, regularArticles, {
                            isFeatured: false,
                            showHeading: hasFeaturedArticles,
                        })}

                    {!hasAnyArticles && (
                        <NoArticlesContainer>
                            <NoArticleHelpText>
                                <FormattedMessage {...messages.articlesEmptyStateText} />
                            </NoArticleHelpText>

                            <ThemedLink to={`/portal/${portalId}`} actionSubjectId="backToPortal">
                                <FormattedMessage {...messages.categoriesEmptyStateLink} values={{ portalName }} />
                            </ThemedLink>
                        </NoArticlesContainer>
                    )}

                    {showRequestCreateButton && <RaiseRequestLink to={portalLink} />}
                </LoadingContainer>
            </ArticleListContainer>
        </AnalyticsContext>
    );
};

export default ArticleList;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const NoArticleHelpText = styled.div`
    ${fonts.regular};
    margin-bottom: ${token('space.100', '8px')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const ArticleOrderedList = styled.ol`
    margin: 0 -${GROUP_BOX_PADDING_LEFT_RIGHT};
    padding: 0;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const SectionHeading = styled.h2`
    ${fonts.h500};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const ArticleListContainer = styled.div<{ marginTop: boolean }>`
    margin-top: ${(props) => (props.marginTop ? token('space.200', '16px') : 0)};

    > * {
        /* This will add a magin to all first level children of this container. */
        margin-top: ${token('space.200', '16px')};
    }
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const NoArticlesContainer = styled.div`
    margin-bottom: ${token('space.500', '40px')};
`;
