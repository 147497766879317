import type { Action } from 'react-sweet-state';
import { createActionsHook, createContainer, createStateHook, createStore } from 'react-sweet-state';
import { RequestCreateViewConfiguration } from '../../utils/configuration';
import type { AdjustedField, InitializeFieldPayload } from './types';

export const requestCreateViewConfiguration = new RequestCreateViewConfiguration();

export const FakeUIMStoreContainer = createContainer();

interface State {
    formData: Record<string, InitializeFieldPayload>;
    appliedChanges: Record<string, AdjustedField> | null;
}

const actions = {
    initializeFields:
        (payload: InitializeFieldPayload[]): Action<State, void, void> =>
        ({ setState }) => {
            const formData = payload.reduce<Record<string, InitializeFieldPayload>>((acc, field) => {
                acc[field.field.fieldId] = field;

                return acc;
            }, {});

            setState({ formData });
        },
    applyOverrides:
        ({
            fieldId,
            prop,
            value,
        }: {
            fieldId: string;
            prop: string;
            value: string | number | null;
        }): Action<State, void, void> =>
        ({ setState, getState }) => {
            const fieldType = getState().formData[fieldId].field.fieldType;
            if (!fieldType) {
                return;
            }

            const fieldConfig = requestCreateViewConfiguration.getSupportedFieldsConfiguration();

            if (!fieldConfig[fieldType]) {
                return;
            }

            const mappedValue = fieldConfig[fieldType].publicToInternalTransformers?.[prop]?.(value) ?? value;

            const prevState = getState().appliedChanges ?? {};
            setState({
                appliedChanges: {
                    ...prevState,
                    [fieldId]: {
                        ...prevState?.[fieldId],
                        [prop]: mappedValue,
                    },
                },
            });
        },
};

type Actions = typeof actions;

const FakeUIMStore = createStore<State, Actions>({
    actions,
    initialState: {
        formData: {},
        appliedChanges: null,
    },
    name: 'fake-uim-store',
    containedBy: FakeUIMStoreContainer,
    handlers: {
        onInit:
            () =>
            ({ dispatch, getState, setState }) => {
                setTimeout(() => {
                    setState({
                        appliedChanges: {
                            summary: {
                                fieldName: '[UIM changed] Summary',
                                value: '[UIM changed] value',
                                description: '[UIM changed] description',
                            },
                            customfield_10093: {
                                fieldName: '[UIM changed] Pending reason',
                                value: '10132',
                                isReadOnly: false,
                                isRequired: true,
                                description: '[UIM changed] description',
                            },
                        },
                    });
                }, 1000);

                // @ts-expect-error
                window.UIM = {
                    logInitializedFields: () => {
                        // eslint-disable-next-line no-console
                        console.table(getState().formData);
                    },
                    logAppliedOverrides: () => {
                        // eslint-disable-next-line no-console
                        console.table(getState().appliedChanges);
                    },
                    change: (fieldId: string, prop: string, value: string | number | null) => {
                        dispatch(actions.applyOverrides({ fieldId, prop, value }));
                    },
                };
            },
    },
});

export const useAdjustedFields = createStateHook(FakeUIMStore, { selector: (state) => state.appliedChanges });
export const useStoreActions = createActionsHook(FakeUIMStore);
