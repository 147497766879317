import { defineMessages } from 'react-intl';

export default defineMessages({
    addAnnoucement: {
        defaultMessage: 'Add announcement',
        description: 'Text for add announcement button',
        id: 'cv.portal.home.add.announcement.button',
    },
    editAnnouncement: {
        defaultMessage: 'Edit announcement',
        description: 'Text for edit announcement button',
        id: 'cv.portal.home.edit.announcement.button',
    },
    requestTypePageTitle: {
        defaultMessage: '{requestTypeName} - {portalName} - Jira Service Management',
        description: 'Page title for portal page when customer selected a request type form',
        id: 'sd.jsm.customerview.request.type.selected.portal.page.title',
    },
    portalPageTitle: {
        defaultMessage: '{portalName} - Jira Service Management',
        description: 'Page title for portal landing page',
        id: 'sd.jsm.customerview.portal.page.title',
    },
    portalGroupPageTitle: {
        defaultMessage: '{portalName} - Raise a request - Jira Service Management',
        description: 'Page title for portal group page',
        id: 'sd.jsm.customerview.portal.group.page.title',
    },
});
