import { defineMessages } from 'react-intl';

export default defineMessages({
    headerTitle: {
        defaultMessage: 'Recently used request forms',
        description: 'Title for recently used forms component in Help Center',
        id: 'cv.smart.portal.recent.request.types.header',
    },
    headerTitleAnon: {
        defaultMessage: 'Suggested forms',
        description: 'Title for recently used forms component in Help Center when user is anonymous',
        id: 'cv.smart.portal.recent.request.types.anonymous.header',
    },
});
