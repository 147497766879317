import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getHelpCenterAri } from 'util/meta';
import type { HtmlAnchor } from '@helpCenter/view/common/analytics/html-anchor';
import { isVAOnHelpCenterGAEnabled, isMoveToManagementRouterEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import Lozenge from '@atlaskit/lozenge';
import { CustomItem } from '@atlaskit/menu';
import { SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { HelpHelpCenterAri } from '@atlassian/ari';
import type { Link } from '@atlassian/help-center-common-component/analytics';
import {
    EngagementMessages,
    EngagementMessagesTarget,
    HELP_CENTERS_MANAGEMENT_BASE_PATH,
} from '@atlassian/help-center-common-component/constants';
import { EngagementCoordinationClient } from '@atlassian/help-center-common-component/engagement-coordination-client';
import { RenderNextAnimationFrame } from '@atlassian/help-center-common-component/render-next-animation-frame';
import { VaOnboardingSpotlight } from '@atlassian/help-center-common-component/va-reporting-onboarding';
import messages from './messages';

interface VirtualAgentMenuProps {
    onClose: (actionSubjectId?: string) => void;
    component: typeof Link | typeof HtmlAnchor;
}

export const VirtualAgentMenu = ({ onClose, component }: VirtualAgentMenuProps) => {
    di(VaOnboardingSpotlight, RenderNextAnimationFrame, EngagementCoordinationClient, getHelpCenterAri);
    const { formatMessage } = useIntl();
    const [showVaReportingOnboardingSpotlight, setShowVaReportingOnboardingSpotlight] = useState(true);
    const { helpCenterId } = HelpHelpCenterAri.parse(getHelpCenterAri());

    const handleClick = () => onClose('navBarVirtualAgent');

    return (
        <>
            <RenderNextAnimationFrame>
                {() => (
                    <SpotlightTarget
                        name={EngagementMessagesTarget.REPORTING_ONBOARDING_SPOTLIGHT}
                        key="navBarVirtualAgent"
                    >
                        <CustomItem
                            key={`customize-menu_navBarVirtualAgent`}
                            component={component}
                            {...(isMoveToManagementRouterEnabled() && {
                                href: `${HELP_CENTERS_MANAGEMENT_BASE_PATH}/${helpCenterId}/virtual-agent`,
                            })}
                            {...(!isMoveToManagementRouterEnabled() && { to: '/virtual-agent' })}
                            actionSubjectId="navBarVirtualAgent"
                            onClick={handleClick}
                            testId="virtual-agent-link"
                            iconAfter={
                                <Lozenge appearance="new">
                                    {formatMessage(isVAOnHelpCenterGAEnabled() ? messages.newLozenge : messages.beta)}
                                </Lozenge>
                            }
                        >
                            <FormattedMessage {...messages.virtualServiceAgent} />
                        </CustomItem>
                    </SpotlightTarget>
                )}
            </RenderNextAnimationFrame>
            {showVaReportingOnboardingSpotlight && (
                <SpotlightTransition>
                    <EngagementCoordinationClient
                        messageId={EngagementMessages.ONBOARD_VA_REPORTING}
                        key={EngagementMessagesTarget.REPORTING_ONBOARDING_SPOTLIGHT}
                    >
                        <VaOnboardingSpotlight
                            setShowVaReportingOnboardingSpotlight={setShowVaReportingOnboardingSpotlight}
                        />
                    </EngagementCoordinationClient>
                </SpotlightTransition>
            )}
        </>
    );
};
