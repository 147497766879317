import React, { useState, useCallback, useEffect } from 'react';
import type { FC, ReactElement } from 'react';
import { styled } from '@compiled/react';
import { isAdvancedHelpCenter, isCSMHelpCenter } from '@helpCenter/util/advanced-help-center';
import { getEnv, getIsHCCustomizationEnabled } from '@helpCenter/util/env';
import { useQueryParams } from '@helpCenter/view/search/common';
import {
    isAdvancedCustomizationEnabled,
    isAdvancedCustomizationEditPageEnabled,
    isCustomiseDropDownChangeBoardingEnabled,
    isBetaTagRemovedForFlexiHCGA,
} from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { useRouter } from 'react-resource-router';
import type { Column } from 'rest/requests-column-settings';
import * as grid from 'view/styles/grid';
import { sizes } from 'view/styles/sizes-viewport';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import MediaServicesBrushIcon from '@atlaskit/icon/glyph/media-services/brush';
import Lozenge from '@atlaskit/lozenge';
import { CustomItem, MenuGroup, Section } from '@atlaskit/menu';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import Popup from '@atlaskit/popup';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { colors, layers } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { Button, Link } from '@atlassian/help-center-common-component/analytics';
import { ChangeBoardingSingleSpotlight } from '@atlassian/help-center-common-component/change-boarding-single-spotlight';
import {
    EngagementMessages,
    HELP_CENTERS_MANAGEMENT_BASE_PATH,
    EngagementMessagesTarget,
} from '@atlassian/help-center-common-component/constants';
import { EngagementCoordinationClient } from '@atlassian/help-center-common-component/engagement-coordination-client';
import { getRelativeBasePath } from '@atlassian/help-center-common-util/history';
import { DynamicThemingButton } from '../dynamic-theming-button';
import EditPageLayoutWithPopUp from '../edit-page-layout-with-popup';

import messages from './messages';

interface Portal {
    id?: number;
    name?: string;
    isProjectSimplified: boolean;
    isProjectAdmin: boolean;
    canEditAnnouncement: boolean;
}
export interface StateProps {
    portal?: Portal;
    isOpen?: boolean;
    canAdministerJIRA: boolean;
    canCustomiseHelpCenter?: boolean;
    defaultColumnSettings?: Column[];
    jsmAIConfigEnabled?: boolean;
    disableCustomiseMenu?: boolean;
}

export interface DispatchProps {
    openHelpCenterCustomizationSidebar: () => void;
    openRequestListCustomizationSidebar: () => void;
}

export type Props = StateProps & DispatchProps;

export interface CustomizeMenuItem {
    link?: string;
    label: string;
    actionSubjectId: string;
    isVisible: boolean | undefined;
    customRenderer?: FC<{ menu: CustomizeMenuItem }>;
    onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
    testId?: string;
    iconAfter?: ReactElement;
    isAnchor?: boolean;
    showSpotlight?: boolean;
}

const isSmallScreen = window.innerWidth < sizes.xsmall;

const CustomizeLinks = (
    onClose: (actionSubjectId?: string) => void,
    openHelpCenterCustomizationSidebar: DispatchProps['openHelpCenterCustomizationSidebar'],
    canAdministerJIRA: boolean,
    showEditLayoutButton: boolean,
    isHomePage: boolean,
    basePath: string,
    handleHideSpotlight: () => void
) => {
    di(
        EngagementCoordinationClient,
        isAdvancedCustomizationEnabled,
        isCustomiseDropDownChangeBoardingEnabled,
        getIsHCCustomizationEnabled,
        isAdvancedCustomizationEditPageEnabled,
        isCSMHelpCenter
    );
    const { formatMessage } = useIntl();

    const getCSMTopicsLabel = () => {
        return isCSMHelpCenter(getEnv().helpCenterType) ? messages.topics : messages.topicsAndPortals;
    };

    const customizeMenu: CustomizeMenuItem[] = [
        {
            link: `${basePath}?customize=true&experience=help-center-announcement`,
            label: formatMessage(messages.announcement),
            actionSubjectId: 'navbarAnnouncement',
            isVisible: isHomePage && !isSmallScreen,
            onClick: () => onClick('navbarAnnouncement'),
            testId: 'announcement-link',
        },
        {
            link: `${basePath}?customize=true&experience=theme`,
            label: formatMessage(messages.look),
            actionSubjectId: 'navbarLookAndFeel',
            isVisible: isHomePage && canAdministerJIRA,
            onClick: () => onClick('navbarLookAndFeel'),
            testId: 'look-and-feel-link',
        },
        {
            link: `${basePath}?customize=true&experience=login-announcement`,
            label: formatMessage(messages.logInMessage),
            actionSubjectId: 'navbarLoginAnnouncement',
            isVisible: isHomePage && canAdministerJIRA && !isSmallScreen,
            onClick: () => onClick('navbarLoginAnnouncement'),
            testId: 'login-announcement-link',
        },
        {
            link: '/settings/portal-reorder',
            label:
                isAdvancedCustomizationEnabled() && isAdvancedCustomizationEditPageEnabled()
                    ? formatMessage(getCSMTopicsLabel())
                    : formatMessage(messages.pageLayout),
            actionSubjectId: 'navbarPortalReorder',
            isVisible: isHomePage && canAdministerJIRA && !isSmallScreen,
            onClick: () => onClick('navbarPortalReorder'),
            testId: 'topics-link',
        },
        {
            link: '/edit',
            label: formatMessage(messages.pageLayout),
            actionSubjectId: 'navbarHideAndReorder',
            isVisible: showEditLayoutButton,
            onClick: () => onClick('navbarHideAndReorder'),
            testId: 'hide-and-reorder-link',
            showSpotlight: true,
            customRenderer: getIsHCCustomizationEnabled() ? undefined : EditPageLayoutWithPopUp,
            iconAfter: isBetaTagRemovedForFlexiHCGA() ? undefined : (
                <Lozenge appearance="new">{formatMessage(messages.beta)}</Lozenge>
            ),
        },
    ];

    const onClick = (actionSubjectId: string) => {
        onClose(actionSubjectId);
        openHelpCenterCustomizationSidebar();
    };
    return (
        <Section>
            {customizeMenu.map((menu) => {
                if (!menu.isVisible) {
                    return null;
                }
                if (menu.customRenderer) {
                    return <menu.customRenderer key={`customize-menu_${menu.actionSubjectId}`} menu={menu} />;
                }
                return (
                    <>
                        <SpotlightTarget name={EngagementMessagesTarget.CHANGE_BOARDING_CUSTOMISE_BUTTON_SPOTLIGHT}>
                            <CustomItem
                                key={`customize-menu_${menu.actionSubjectId}`}
                                component={CustomLink}
                                to={menu.link}
                                actionSubjectId={menu.actionSubjectId}
                                onClick={menu.onClick}
                                testId={menu.testId}
                                iconAfter={menu.iconAfter}
                            >
                                {menu.label}
                            </CustomItem>
                        </SpotlightTarget>
                        {menu.showSpotlight && isCustomiseDropDownChangeBoardingEnabled() && (
                            <EngagementCoordinationClient messageId={EngagementMessages.CHANGE_BOARD_CUSTOMISE_BUTTON}>
                                <ChangeBoardingSingleSpotlight
                                    target={EngagementMessagesTarget.CHANGE_BOARDING_CUSTOMISE_BUTTON_SPOTLIGHT}
                                    analyticsSubject="customiseButtonChangeBoarding"
                                    messageId={EngagementMessages.CHANGE_BOARD_CUSTOMISE_BUTTON}
                                    contentText={formatMessage(messages.changeBoardingText)}
                                    buttonText={formatMessage(messages.changeBoardingButtonText)}
                                    dialogPlacement="bottom right"
                                    targetBgColor={token('elevation.surface.raised', colors.N0)}
                                    onHideSpotlight={handleHideSpotlight}
                                />
                            </EngagementCoordinationClient>
                        )}
                    </>
                );
            })}
        </Section>
    );
};

const PortalLinks = ({
    onClose,
    canCustomiseHelpCenter,
    portal,
}: {
    onClose: (actionSubjectId?: string) => void;
    canCustomiseHelpCenter: boolean | undefined;
    portal: Portal | undefined;
}) => {
    const { formatMessage } = useIntl();
    if (!portal) {
        return null;
    }
    const { isProjectSimplified, isProjectAdmin, canEditAnnouncement } = portal;
    const shouldRenderAsPortalAnnouncement = (): boolean => {
        // for simplified projects, render side bar as announcement for agents with permission
        if (isProjectSimplified && !isProjectAdmin && canEditAnnouncement) {
            return true;
        }
        // in classic projects render sidebar as announcement for users who can't modify helpcenter, but can edit announcements
        if (!isProjectSimplified && !canCustomiseHelpCenter && canEditAnnouncement) {
            return true;
        }
        return false;
    };

    const customizeMenu = [
        {
            link: '?customize=true&experience=add-announcement',
            onClick: () => onClose('navbarPortalAnnouncement'),
            label: formatMessage(messages.portalAnnouncement),
            actionSubjectId: 'navbarPortalAnnouncement',
            isVisible: canEditAnnouncement || shouldRenderAsPortalAnnouncement(),
        },
        {
            link: '?customize=true&experience=request-groups',
            onClick: () => onClose('navbarRequestTypeGroup'),
            label: formatMessage(messages.portalRequestTypeGroup),
            actionSubjectId: 'navbarRequestTypeGroup',
            isVisible: isProjectAdmin && isProjectSimplified && !shouldRenderAsPortalAnnouncement(),
        },
    ];

    return (
        <Section>
            {customizeMenu.map((menu) => {
                if (!menu.isVisible) {
                    return null;
                }
                return (
                    <CustomItem
                        key={`portal-link_${menu.actionSubjectId}`}
                        component={CustomLink}
                        to={menu.link}
                        actionSubjectId={menu.actionSubjectId}
                        onClick={menu.onClick}
                    >
                        {menu.label}
                    </CustomItem>
                );
            })}
        </Section>
    );
};

const RequestList = (
    onClose: (actionSubjectId?: string) => void,
    openRequestListCustomizationSidebar: DispatchProps['openRequestListCustomizationSidebar']
) => {
    const onClick = (actionSubjectId: string) => {
        onClose(actionSubjectId);
        openRequestListCustomizationSidebar();
    };
    const { formatMessage } = useIntl();
    return (
        <Section>
            <CustomItem
                component={CustomButton}
                actionSubjectId="navbarUserRequestListCustomize"
                aria-label={formatMessage(messages.customizeRequestList)}
                onClick={() => onClick('navbarUserRequestListCustomize')}
                data-test-id="requests-list-page-link"
            >
                {formatMessage(messages.customizeRequestList)}
            </CustomItem>
        </Section>
    );
};

const CustomiseMenu = (props: Props) => {
    di(
        useAnalyticsEvents,
        getRelativeBasePath,
        isAdvancedHelpCenter,
        isAdvancedCustomizationEnabled,
        isAdvancedCustomizationEditPageEnabled,
        useRouter
    );
    const [{ route }] = useRouter();
    const { name: routeName } = route;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { customize, experience } = useQueryParams();

    useEffect(() => {
        // if customize is true and experience is not set, open the customise menu
        if (customize === 'true' && !experience) {
            setIsOpen(true);
        }
    }, [customize, experience]);

    const isHomepage = routeName === 'help-center' || routeName === 'view-homepage';
    const showEditLayoutButton =
        isAdvancedCustomizationEnabled() &&
        isAdvancedCustomizationEditPageEnabled() &&
        isHomepage &&
        props.canAdministerJIRA &&
        !isSmallScreen &&
        !isCSMHelpCenter(getEnv().helpCenterType);
    const isHelpCenterManagementScreen = getRelativeBasePath() === HELP_CENTERS_MANAGEMENT_BASE_PATH;
    const basePath = isAdvancedHelpCenter() ? '/' : '/portals';
    const [isOpen, setIsOpen] = useState(!!props.isOpen);

    const { formatMessage } = useIntl();

    const handleHideSpotlight = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onOpenChange = useCallback((open: boolean) => {
        setIsOpen(open);
    }, []);

    const { createAnalyticsEvent } = useAnalyticsEvents();
    const onItemClick = (actionSubjectId?: string) => {
        if (actionSubjectId && createAnalyticsEvent) {
            createAnalyticsEvent({
                actionSubjectId,
                action: 'clicked',
                actionSubject: 'customizeMenu',
                analyticsType: UI_EVENT_TYPE,
            }).fire();
        }
        setIsOpen(false);
    };

    const allowRouteList = ['help-center', 'view-homepage', 'portal-home', 'requests-list'];

    const hideCustomizeButton = () =>
        !allowRouteList.includes(routeName) ||
        isHelpCenterManagementScreen ||
        (isHomepage && !props.canCustomiseHelpCenter) ||
        //Hide for Agents with Edit Permission in mobile view
        (isHomepage && props.canCustomiseHelpCenter && !props.canAdministerJIRA && isSmallScreen) ||
        (routeName === 'portal-home' && !(props.portal?.isProjectAdmin || props.portal?.canEditAnnouncement)) ||
        (routeName === 'requests-list' && !props.canAdministerJIRA);

    const content = () => {
        const {
            openHelpCenterCustomizationSidebar,
            openRequestListCustomizationSidebar,
            canAdministerJIRA,
            canCustomiseHelpCenter,
            defaultColumnSettings,
            portal,
        } = props;
        return (
            <Box
                xcss={popupContainerStyles}
                aria-label={formatMessage(messages.customizeAriaLabel)}
                testId={'customize-menu-content'}
            >
                <MenuGroup maxHeight={maxHeight} spacing={'cozy'}>
                    {!isHelpCenterManagementScreen && (
                        <>
                            {canCustomiseHelpCenter &&
                                isHomepage &&
                                CustomizeLinks(
                                    onItemClick,
                                    openHelpCenterCustomizationSidebar,
                                    canAdministerJIRA,
                                    showEditLayoutButton,
                                    isHomepage,
                                    basePath,
                                    handleHideSpotlight
                                )}
                            {routeName === 'portal-home' && (
                                <PortalLinks
                                    onClose={onItemClick}
                                    canCustomiseHelpCenter={canCustomiseHelpCenter}
                                    portal={portal}
                                />
                            )}
                            {routeName === 'requests-list' &&
                                canAdministerJIRA &&
                                Boolean(defaultColumnSettings?.length) &&
                                RequestList(onItemClick, openRequestListCustomizationSidebar)}
                        </>
                    )}
                </MenuGroup>
            </Box>
        );
    };

    const Trigger = useCallback(
        ({ ref, ...triggerProps }: Partial<TriggerProps>) => {
            return (
                <div role="listitem">
                    <DynamicThemingButton
                        label={formatMessage(messages.customizeBtnLabel)}
                        icon={<MediaServicesBrushIcon label="customise" />}
                        disabled={props.disableCustomiseMenu}
                        onClick={() => {
                            onOpenChange(!isOpen);
                            createAnalyticsEvent({
                                action: 'clicked',
                                actionSubject: 'button',
                                actionSubjectId: 'navbarCustomise',
                                analyticsType: UI_EVENT_TYPE,
                            }).fire();
                        }}
                        forwardedRef={ref}
                        {...triggerProps}
                    />
                </div>
            );
        },
        [formatMessage, props.disableCustomiseMenu, onOpenChange, isOpen, createAnalyticsEvent]
    );

    const renderDropdownMenu = () => (
        <SpotlightManager blanketIsTinted>
            <Popup
                isOpen={isOpen}
                onClose={() => onOpenChange(false)}
                placement="bottom-end"
                shouldRenderToParent
                zIndex={layers.modal()}
                content={content}
                trigger={Trigger}
                key={'customise-menu-dumb-popup'}
            />
        </SpotlightManager>
    );

    if (hideCustomizeButton()) {
        return null;
    }

    return <Box xcss={CustomiseBtnWrapperStyles}>{renderDropdownMenu()}</Box>;
};

const CustomiseBtnWrapperStyles = xcss({
    marginRight: 'space.100',
    marginLeft: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const NoUnderlineLink = styled(Link)({
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
        textDecoration: 'none',
    },
});

const popupContainerStyles = xcss({
    minWidth: '234px',
    maxWidth: '800px',
    color: 'color.text',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CustomLink = styled(NoUnderlineLink)({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: 'unset !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CustomButton = styled(Button)({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: `${token('color.text', colors.N800)} !important`,
    '&:focus, &:hover, &:visited, &:link, &:active': {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        color: `${token('color.text', colors.N800)} !important`,
    },
});

const maxHeight = `calc(100vh - ${grid.multiple(24).px}`;

export default CustomiseMenu;
