import { getCloudId, getHelpCenterAri } from 'util/meta';
import environment from 'util/relay/environment';
import { fetchQuery, graphql } from 'relay-runtime';
import type { LoginAnnouncementsResponse } from 'rest/login-announcements';
import type { HelpCenterTranslation } from '../types';
import type {
    loginAnnouncementsQuery,
    loginAnnouncementsQuery$data,
} from './__generated__/loginAnnouncementsQuery.graphql';
import type { TranslatedLoginAnnouncements } from '@helpCenter/rest/login-announcements/types';

export const fetchLoginAnnouncementsData = () => {
    return fetchQuery<loginAnnouncementsQuery>(
        environment,
        graphql`
            query loginAnnouncementsQuery($cloudId: ID!, $helpCenterAri: ID!) {
                helpCenter(cloudId: $cloudId) {
                    helpCenterById(helpCenterAri: $helpCenterAri) @optIn(to: ["HelpCenterAggBeta"]) {
                        ... on HelpCenter {
                            __typename
                            siteDefaultLanguageTag
                            announcements {
                                loginAnnouncements {
                                    nameTranslationsRaw {
                                        locale
                                        localeDisplayName
                                        value
                                    }
                                    descriptionTranslationsRaw {
                                        locale
                                        localeDisplayName
                                        value
                                    }
                                }
                            }
                        }
                        ... on QueryError {
                            __typename
                        }
                    }
                }
            }
        `,
        {
            cloudId: getCloudId(),
            helpCenterAri: getHelpCenterAri(),
        }
    );
};

const mergeNameAndDescriptionTranslations = (
    nameTranslationsRaw: readonly HelpCenterTranslation[],
    descriptionTranslationsRaw: readonly HelpCenterTranslation[]
): TranslatedLoginAnnouncements => {
    const translatedLoginAnnouncements: TranslatedLoginAnnouncements = {};

    nameTranslationsRaw?.forEach((translationName) => {
        const locale = translationName.locale;
        const header = translationName.value;
        const targetDescriptionAnnouncement = descriptionTranslationsRaw?.find(
            (translationDescription) => translationDescription.locale === locale
        );
        translatedLoginAnnouncements[locale] = {
            header,
            message: targetDescriptionAnnouncement?.value || '',
            localeDisplayName: translationName.localeDisplayName,
        };
    });
    return translatedLoginAnnouncements;
};

export const convertToLoginAnnouncementsResponse = (
    response: loginAnnouncementsQuery$data | undefined
): LoginAnnouncementsResponse | null => {
    if (response === undefined) {
        return null;
    }
    const data = response?.helpCenter?.helpCenterById;
    if (data?.__typename === 'QueryError') {
        return null;
    }
    if (data?.__typename === 'HelpCenter' && data.announcements?.loginAnnouncements?.length) {
        // Currently we only support one login announcement
        const targetLoginAnnouncement = data.announcements.loginAnnouncements[0];

        return {
            translatedLoginAnnouncements: mergeNameAndDescriptionTranslations(
                targetLoginAnnouncement.nameTranslationsRaw || [],
                targetLoginAnnouncement.descriptionTranslationsRaw || []
            ),
            defaultLocale: data.siteDefaultLanguageTag || '',
        };
    }
    if (data?.__typename === 'HelpCenter' && data.siteDefaultLanguageTag) {
        // case then loginAnnouncements array is empty but siteDefaultLanguageTag is present, we have to map default locale
        return {
            translatedLoginAnnouncements: {
                [data.siteDefaultLanguageTag]: {
                    localeDisplayName: data.siteDefaultLanguageTag,
                    header: '',
                    message: '',
                },
            },
            defaultLocale: data.siteDefaultLanguageTag,
        };
    }
    return null;
};
