import React, { useMemo } from 'react';
import { isFlexiHCEnabledInDefaultHC } from 'util/advanced-help-center';
import { UFOWatcher } from 'util/ufo-watcher';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import { ThemeProvider } from 'styled-components';
import {
    isEmailVerifiedSignalEnabled,
    isProjectMappingInHelpCenterEnabled,
    isMoveToManagementRouterEnabled,
} from 'feature-flags';
import { createMemoryHistory } from 'history';
import type { Route } from 'react-resource-router';
import { RouteComponent, Router } from 'react-resource-router';
import { getViewHomePageRoute, getEditHomePageRoute } from 'routes/advanced-help-center';
import { aiAnswerRoute } from 'routes/aianswer';
import { approvalsRoute } from 'routes/approvals';
import { consentFormRoute } from 'routes/consent-form';
import { getHelpCenterConversationPageRoute, viewPortalConversationPageRoute } from 'routes/conversation';
import { editTopicRoute, addTopicRoute } from 'routes/edit-topic';
import { embedCreateRequestRoute, embedSinglePortalRoute, embedKBArticleRoute } from 'routes/embed-smartlinks';
import { getHelpCenterRoute } from 'routes/help-center';
import { helpcenterEditRoute, helpcenterManageProjectsRoute } from 'routes/help-center-settings';
import { helpcenterVirtualAgentRoute } from 'routes/help-center-virtual-agent';
import {
    viewAllSearchResultsRoute,
    viewPortalAllSearchResultsRoute,
    viewArticleRoute,
    viewCrossSiteArticleRoute,
    viewKbPageRoute,
    viewPortalArticleRoute,
    viewCrossSitePortalArticleRoute,
    viewPortalCategoryArticleRoute,
    viewPortalKnowledgeCategoryRoute,
} from 'routes/kb';
import { loginRoute, portalLoginRoute } from 'routes/login';
import { portalAccessForbiddenRoute } from 'routes/portal-access-forbidden';
import { getPortalHomeRoutes } from 'routes/portal-home';
import { requestApprovalRoute } from 'routes/request-approval';
import { requestDetailsRoute } from 'routes/request-details';
import { requestsListRoute } from 'routes/requests';

import {
    portalInvitedSignupRoute,
    portalCompleteSignupRoute,
    portalResetPasswordRoute,
    portalSignupRoute,
    userCheckMailRoute,
    userCompleteSignupRoute,
    userResetPasswordRoute,
    userSignupRoute,
} from 'routes/signup';
import { viewTopicRoute } from 'routes/topics';
import { userVerifyEmailRoute } from 'routes/verify-email';
import { getHeadless } from 'state/selectors/env';
import { getHelpCenterTitleColor, getThemeColor } from 'state/selectors/help-center';
import { lightenColor, readableTextColor } from 'view/styles/colors';
import { GlobalStyled } from 'view/styles/global';
import { isFedRamp } from '@atlassian/atl-context';
import { isAdvancedHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { history, getBaseName } from '@atlassian/help-center-common-util/history';
import { resourcesPlugin } from '../resource-plugin';
import {
    requestFeedbackRoute,
    unsubscribeRoute,
    userProfileRoute,
    anonymousRequestSuccessRoute,
    getRequestCreateRoute,
    portalReorderRoute,
    notFoundErrorRoute,
} from '../routes';

export const getRoutes = (): Route[] => {
    return [
        ...(isAdvancedHelpCenter() || isFlexiHCEnabledInDefaultHC()
            ? [getViewHomePageRoute(), getEditHomePageRoute()]
            : []),
        ...(isEmailVerifiedSignalEnabled() ? [userVerifyEmailRoute] : []),
        viewPortalConversationPageRoute,
        aiAnswerRoute,
        consentFormRoute,
        loginRoute,
        userResetPasswordRoute,
        userSignupRoute,
        userCompleteSignupRoute,
        userCheckMailRoute,
        portalAccessForbiddenRoute,
        portalLoginRoute,
        portalSignupRoute,
        portalResetPasswordRoute,
        portalCompleteSignupRoute,
        portalInvitedSignupRoute,
        requestFeedbackRoute,
        unsubscribeRoute,
        getHelpCenterRoute(),
        getEditHomePageRoute('edit-homepage', '/edit'),
        requestDetailsRoute,
        viewKbPageRoute,
        viewAllSearchResultsRoute,
        viewPortalAllSearchResultsRoute,
        viewPortalCategoryArticleRoute,
        viewPortalArticleRoute,
        viewCrossSitePortalArticleRoute,
        viewArticleRoute,
        viewCrossSiteArticleRoute,
        requestsListRoute,
        approvalsRoute,
        requestApprovalRoute,
        userProfileRoute,
        ...(isProjectMappingInHelpCenterEnabled() && !isMoveToManagementRouterEnabled()
            ? [helpcenterEditRoute, helpcenterManageProjectsRoute]
            : []),
        ...(!isFedRamp() && !isMoveToManagementRouterEnabled() ? [helpcenterVirtualAgentRoute] : []),
        viewPortalKnowledgeCategoryRoute,
        anonymousRequestSuccessRoute,
        getRequestCreateRoute(),
        getPortalHomeRoutes(),
        embedCreateRequestRoute,
        embedSinglePortalRoute,
        embedKBArticleRoute,
        portalReorderRoute(true),
        editTopicRoute,
        addTopicRoute,
        viewTopicRoute(),
        ...getHelpCenterConversationPageRoute(),
        notFoundErrorRoute,
    ];
};

export interface Props {
    headerless: boolean;
    themeColor: string;
    titleColor: string;
}

export const JsmResourceRouter = (props: Props) => {
    const { themeColor, titleColor } = props;
    const readableTextColorFromTheme = readableTextColor(themeColor);
    const lightColorFromTheme = lightenColor(themeColor);
    const routes = useMemo(() => getRoutes(), []);
    const location = window.location.pathname + window.location.search;
    const theme = {
        titleColor,
        readableTextColor: readableTextColorFromTheme,
        color: themeColor,
        lightColor: lightColorFromTheme,
    };

    if (__SERVER__) {
        return (
            <ThemeProvider theme={theme}>
                <Router
                    routes={routes}
                    isGlobal={false}
                    basePath={getBaseName()}
                    history={createMemoryHistory({ initialEntries: [location] })}
                    plugins={[resourcesPlugin]}
                >
                    <GlobalStyled />
                    <RouteComponent />
                </Router>
            </ThemeProvider>
        );
    }

    //isGlobal=false, to avoid the router conflicting with the sidebar's router.
    return (
        <ThemeProvider theme={theme}>
            <Router routes={routes} history={history} isGlobal={false} plugins={[resourcesPlugin]}>
                <GlobalStyled />
                {!__SERVER__ ? <UFOWatcher /> : null}
                <RouteComponent />
            </Router>
        </ThemeProvider>
    );
};

const selector = createSelector(
    getHeadless,
    getThemeColor,
    getHelpCenterTitleColor,
    (headerless, themeColor, titleColor) => ({
        headerless,
        themeColor,
        titleColor,
    })
);

export default connect(selector)(JsmResourceRouter);
