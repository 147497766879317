import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';

import { SidebarToggle } from 'util/sidebar-toggle';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { LazySuspense } from 'react-loosely-lazy';
import { BANNER_HEIGHT, HEADER_HEIGHT } from 'view/common/styled';
import { HelpCenterCustomizeSidebar } from 'view/help-center-customize-sidebar';
import { Search } from 'view/search';
import * as bp from 'view/styles/breakpoints';
import * as fonts from 'view/styles/fonts';
import { sizes } from 'view/styles/sizes-viewport';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { token } from '@atlaskit/tokens';
import { DocumentTitle } from '@atlassian/help-center-common-component/document-title';
import type { PushingCustomizeSidebarProps } from '@atlassian/help-center-common-component/pushing-customize-sidebar/types';
import type { InjectedCoordinationClientProps } from '@atlassian/help-center-common-util/coordination-client-provider';
import { withCoordinationClient } from '@atlassian/help-center-common-util/coordination-client-provider';
import { clearSidebarExperienceAndCustomize } from '@atlassian/help-center-common-util/sidebar-experience';
import { LazyHelpCenterBucketsRoutes } from '../help-center-customize-sidebar/bucket-routes';
import { HelpCenterContainer } from './container';
import { HelpCenterContent } from './help-center-content';
import messages from './messages';
import type { WithScreenSizeProps } from './use-screen-size';
import { withMatchScreenSize } from './use-screen-size';

export interface HelpCenterProps extends InjectedIntlProps, WithScreenSizeProps {
    helpCenterTitle?: string;
    titleColor?: string;
    showCustomizeSidebar: boolean;
    sharedPortalName: string | undefined;
    onStartEditing: () => void;
    onFinishEditing: () => void;
    isCustomizing: boolean;
    searchBarShown: boolean;
    canAdministerJira: boolean;
    isPortalHidingEnabled: boolean;
    PushingCustomizeSidebar: React.ComponentType<PushingCustomizeSidebarProps>;
}

interface ModifiedHTMLElement extends HTMLElement {
    ref: HTMLElement | null;
}

export class HelpCenter extends React.Component<HelpCenterProps & InjectedCoordinationClientProps> {
    ref: HTMLElement | null;

    handleFinishEditing = () => {
        clearSidebarExperienceAndCustomize();
        this.props.onFinishEditing();
        this.setFocus();
    };

    setFocus() {
        this.ref && this.ref.focus();
    }

    onRef = (el: ModifiedHTMLElement | null) => {
        // https://github.com/styled-components/styled-components/issues/618
        this.ref = el && el.ref;
    };

    onOpen = () => {
        this.setState({ showPulse: false });
        this.props.onStartEditing();
    };

    render() {
        const {
            helpCenterTitle,
            titleColor,
            showCustomizeSidebar,
            sharedPortalName,
            intl,
            isCustomizing,
            PushingCustomizeSidebar,
            searchBarShown,
            isMediaQueryMatch: isSmallScreenSize,
        } = this.props;

        const title = intl.formatMessage(messages.helpCenterTitle, { helpCenterTitle: sharedPortalName });

        return (
            <DocumentTitle title={title} ignoreBaseTitle>
                <>
                    {showCustomizeSidebar && (
                        <LazySuspense fallback={<UFOLoadHold name="customize-sidebar-loader" />}>
                            {!searchBarShown ? (
                                <PushingCustomizeSidebar
                                    isOpen={isCustomizing}
                                    onOpen={this.onOpen}
                                    onClose={this.handleFinishEditing}
                                >
                                    {!isSmallScreenSize ? (
                                        <HelpCenterBucketsContainer>
                                            <LazyHelpCenterBucketsRoutes
                                                isOpen={isCustomizing}
                                                onClose={this.handleFinishEditing}
                                            />
                                        </HelpCenterBucketsContainer>
                                    ) : (
                                        <HelpCenterCustomizeSidebar
                                            isOpen={isCustomizing}
                                            onClose={this.handleFinishEditing}
                                        />
                                    )}
                                </PushingCustomizeSidebar>
                            ) : null}
                        </LazySuspense>
                    )}
                    <SidebarToggle openSidebar={this.onOpen} closeSidebar={this.handleFinishEditing} />
                    <SearchContainer>
                        <HelpCenterTitle titleColor={titleColor}>{helpCenterTitle}</HelpCenterTitle>
                        <Search />
                    </SearchContainer>

                    <HelpCenterContent isPortalHidingEnabled={this.props.isPortalHidingEnabled} />
                </>
            </DocumentTitle>
        );
    }
}

export default withCoordinationClient(injectIntl(withMatchScreenSize(sizes.medium)(HelpCenter)));

const SEARCH_CONTAINER_PADDING = token('space.600', '48px');

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SearchContainer = styled(HelpCenterContainer)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: ${SEARCH_CONTAINER_PADDING};
    padding-bottom: ${SEARCH_CONTAINER_PADDING};
    height: ${BANNER_HEIGHT.unitless - HEADER_HEIGHT}px;
`;

interface HelpCenterTitleProps {
    titleColor?: string;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HelpCenterTitle = styled.h1<HelpCenterTitleProps>`
    ${fonts.h700};
    margin-bottom: ${token('space.100', '8px')};
    color: ${(props) => (!!props.titleColor ? props.titleColor : props.theme.readableTextColor)};
    text-align: center;

    ${bp.fromXXSmall.css`
        text-align: left;
    `};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HelpCenterBucketsContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
