import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as grid from 'view/styles/grid';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import StarIcon from '@atlaskit/icon/glyph/star';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import { fontSize, colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { SkeletonItem } from '@atlassian/help-center-common-component/skeleton-item';
import { cardStyle } from '../portal-card';
import { DragHandlerWrapper, cardGutterStyle } from '../project-card';
import { IconContainer, Content, Container, titleSkeletonHeight } from './styled';

import type { ProjectCardSkeletonProps, BasicProjectCardSkeletonProps } from './types';

const ProjectsCardSkeleton: React.FunctionComponent<ProjectCardSkeletonProps | BasicProjectCardSkeletonProps> = ({
    isBasic,
    isFeatured,
}) => {
    return (
        <Root>
            <Container hasIcon>
                {!isBasic && isFeatured && (
                    <ActionsLeft>
                        <DragHandlerWrapper>
                            <DragHandlerIcon label="drag-handler" size="xlarge" />
                        </DragHandlerWrapper>
                    </ActionsLeft>
                )}
                <IconContainer>
                    <SkeletonItem width={grid.multiple(6).px} height={grid.multiple(6).px} />
                </IconContainer>
                <Content>
                    <NameWrapper>
                        <SkeletonItem width={grid.multiple(11).px} height={titleSkeletonHeight} />
                    </NameWrapper>
                    <SkeletonItem width={grid.multiple(18).px} height={`${fontSize()}px`} />
                </Content>
                {!isBasic && (
                    <Actions>
                        {isFeatured ? (
                            <>
                                <StarFilledIcon label="star-filled-icon" />
                            </>
                        ) : (
                            <StarIcon label="start-icon" />
                        )}
                    </Actions>
                )}
            </Container>
        </Root>
    );
};

export default ProjectsCardSkeleton;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Root = styled.li`
    ${cardStyle}
    ${cardGutterStyle}
    border: solid 1px ${token('color.border', colors.N40)};
    background-color: ${token('elevation.surface.raised', colors.N0)};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const NameWrapper = styled.div`
    margin-bottom: ${token('space.100', '8px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Actions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    color: ${token('color.skeleton', colors.N20)};
    top: ${token('space.100', '8px')};
    right: ${token('space.100', '8px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ActionsLeft = styled(Actions)`
    position: initial;
    width: ${grid.multiple(5).px};
    margin-top: -${grid.multiple(6).px};
`;
