import type { StoreActionApi } from 'react-sweet-state';
import type { Option } from '../../../common/types';
import type { State } from '../types';
import { constructHelpseekerMessage } from './construct-helpseeker-message';
import { sendHelpseekerMessage } from './send-helpseeker-message';

export const selectOption =
    (option: Option) =>
    ({ dispatch }: StoreActionApi<State>) => {
        const helpseekerMessage = dispatch(
            constructHelpseekerMessage({
                authorType: 'HELPSEEKER',
                optionId: option.id,
                content: option.label || '',
            })
        );
        return dispatch(sendHelpseekerMessage(helpseekerMessage));
    };
