import React from 'react';
import { Box, Grid, xcss } from '@atlaskit/primitives';
import { MainContentSkipLinkWrapper } from '../../common/skip-links';
import { StyledFooter, NoteBackground, NoteContent } from '../paper/styled';
import type { GridAppearanceTypes } from './utils';
import { getContentWidth } from './utils';

interface SkipToMainContentProps {
    children: React.ReactNode;
}

const SkipToMainContent = ({ children }: SkipToMainContentProps) => (
    <MainContentSkipLinkWrapper>
        <Box testId="skip-to-main-content" xcss={mainContentSkipLinkStyles}>
            {children}
        </Box>
    </MainContentSkipLinkWrapper>
);

interface Props {
    children: React.ReactNode;
    appearance?: GridAppearanceTypes;
    isCSM?: boolean;
}

interface GridContentWrapperProps {
    children: JSX.Element;
    appearance: GridAppearanceTypes;
    hide: boolean;
}

const GridContentWrapper: React.FC<GridContentWrapperProps> = ({ children, appearance, hide }) => {
    const gridWidth = getContentWidth(appearance);

    return (
        <Box testId="grid-content-wrapper" xcss={staticWrapperStyles} style={{ maxWidth: hide ? '100%' : gridWidth }}>
            {children}
        </Box>
    );
};

const GridContainer = ({ children, isCSM, appearance: contentAppearance }: Props) => {
    const hide = contentAppearance === 'none';
    const appearance = contentAppearance || 'normal';

    if (appearance === 'note') {
        return (
            <Box xcss={noteContainerStyles}>
                <NoteContent appearance={appearance} hide={hide} data-testid="note-content">
                    <NoteBackground data-testid="note-background">{children}</NoteBackground>
                    <StyledFooter />
                </NoteContent>
            </Box>
        );
    }

    return (
        <Box as="section" xcss={sectionStyles}>
            <GridContentWrapper appearance={appearance} hide={hide}>
                <Grid xcss={gridContainerStyles} justifyContent="center">
                    <Grid xcss={gridItemStyles}>
                        <SkipToMainContent>
                            {children}
                            <StyledFooter isCSM={isCSM} />
                        </SkipToMainContent>
                    </Grid>
                </Grid>
            </GridContentWrapper>
        </Box>
    );
};

const sectionStyles = xcss({
    flex: 1,
});

const staticWrapperStyles = xcss({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    margin: '0 auto',
});

const gridContainerStyles = xcss({
    flex: 1,
    gridTemplateColumns: 'repeat(12, 1fr)',
});

const gridItemStyles = xcss({
    gridColumn: 'span 12',
});

const mainContentSkipLinkStyles = xcss({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: '100%',
});

const noteContainerStyles = xcss({
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
});

export default GridContainer;
