import { defineMessages } from 'react-intl';

export default defineMessages({
    serviceDeskFilter: {
        defaultMessage: 'Service desk',
        description: 'Title of the service desk picker',
        id: 'sd.all.articles.result.servicedesk.filter.name',
    },
    portal: {
        defaultMessage: 'Portal',
        description: 'Title of the portal picker',
        id: 'cv.search.servicedesk-filter.portal',
    },
});
