import { defineMessages } from 'react-intl';

export default defineMessages({
    inputPlaceholder: {
        defaultMessage: 'Search for help resources',
        description: 'Placeholder message for the search bar describing what they can look for',
        id: 'cv.search.search-bar.input.placeholder',
    },
    close: {
        defaultMessage: 'Close search',
        description: 'Label for the close button which closes the search overlay',
        id: 'cv.search.search-bar.close',
    },
    inputLabel: {
        defaultMessage: 'Search bar input',
        description: 'Label message for the search bar input',
        id: 'cv.search.search-bar.input-label',
    },
    searchIconLabel: {
        defaultMessage: 'Search icon',
        description: 'Label message for the search bar input icon',
        id: 'cv.search.search-bar.search-icon-label',
    },
});
