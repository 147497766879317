import { di } from 'react-magnetic-di';
import { useSendMessage as useSendVirtualAgentMessage } from '@atlassian/help-center-virtual-agent/src/controllers/send-message';
import { ConversationProvider } from '../../../common/types';
import { useActions as useAIAnswersActions } from '../../../store/converse';
import { useProvider } from '../../../store/routing';

export const useSendMessage = () => {
    di(useProvider, useAIAnswersActions, useSendVirtualAgentMessage);
    const provider = useProvider();
    const { sendMessage: sendVirtualAgentMessage } = useSendVirtualAgentMessage();
    const { addHelpseekerMessage: addAIAnswersMessage } = useAIAnswersActions();

    const sendMessage = (message: string) => {
        provider === ConversationProvider.AI_ANSWERS ? addAIAnswersMessage(message) : sendVirtualAgentMessage(message);
    };

    return { sendMessage };
};
