import { defineMessages } from 'react-intl';

export default defineMessages({
    customizeBtnLabel: {
        defaultMessage: 'Customize',
        description:
            'Button label for customize button beside avatar menu in header to open list of customization options in a popup',
        id: 'customize.menu.header.customizeBtnLabel',
    },
    customizeRequestList: {
        defaultMessage: 'Manage columns',
        description: 'Manage columns item in customize menu in request list screen',
        id: 'customize.menu.header.manage-column',
    },
    pageLayout: {
        defaultMessage: 'Edit page layout',
        description: 'Edit page layout item in customize drop-down menu',
        id: 'customize.menu.header.pageLayout',
    },
    beta: {
        defaultMessage: 'Beta',
        description: 'Beta lozenge for flexi HC in customize drop-down menu',
        id: 'customize.menu.header.label.beta',
    },
    changeBoardingText: {
        defaultMessage:
            'Make your help content stand out. Reorder or hide sections, and customize your home page with rich content for different audiences.',
        description: 'Text for describing the change boarding of customize button',
        id: 'customize.menu.header.pageLayout.change.board',
    },
    changeBoardingButtonText: {
        defaultMessage: 'OK',
        description: 'Text to close the change boarding of the customize button',
        id: 'customize.menu.header.pageLayout.change.board.ok',
    },
    topicsAndPortals: {
        defaultMessage: 'Manage topics and portals',
        description: 'Manage Topics and Portals item in customize drop-down menu when advanced customization enabled',
        id: 'customize.menu.header.topicsAndPortals',
    },
    topics: {
        defaultMessage: 'Manage topics',
        description: 'Manage Topics item in customize drop-down menu when advanced customization enabled',
        id: 'customize.menu.header.topics',
    },
    look: {
        defaultMessage: 'Customize look and feel',
        description: 'Customize look and feel item in customize menu',
        id: 'customize.menu.header.look',
    },
    announcement: {
        defaultMessage: 'Manage announcements',
        description: 'Announcement item in customize menu',
        id: 'customize.menu.header.announcement',
    },
    logInMessage: {
        defaultMessage: 'Add login page message',
        description: 'Add login page message item in customize menu',
        id: 'customize.menu.header.logInMessage',
    },
    customizeAriaLabel: {
        defaultMessage: 'Customization options',
        description:
            'Aria label for screen readers while opening customize pop up menu from customize button in header',
        id: 'customize.menu.header.customizeAriaLabel',
    },
    portalAnnouncement: {
        defaultMessage: 'Manage announcements',
        description: 'Manage announcement item in customize menu in portal screen',
        id: 'customize.menu.header.portalAnnouncement',
    },
    portalRequestTypeGroup: {
        defaultMessage: 'Organize portal groups',
        description: 'Organize portal groups item in customize menu for portal screen',
        id: 'customize.menu.header.requestTypeGroup',
    },
    pageLayoutPremiumDescription: {
        defaultMessage: 'You need to be on a Premium or Enterprise plan to edit sections in your home page layout.',
        description: 'Description for premium suggestion of feature Page Layout',
        id: 'customize.menu.header.pageLayout.premium.description',
    },
    pageLayoutTryButton: {
        defaultMessage: 'Try it now',
        description: 'Button to try the premium feature Page Layout',
        id: 'customize.menu.header.pageLayout.premium.try.button',
    },
});
