import React from 'react';
import { isUseHelpCenterRefresh } from 'feature-flags';
import { useRouter } from 'react-resource-router';
import invariant from 'tiny-invariant';
import type { PaperAppearance } from 'view/layout/paper';
import { ViewTopic } from 'view/topic';
import type { topicQuery } from 'view/topic/__generated__/topicQuery.graphql';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout, useRelayResource } from '@atlassian/help-center-common-util/resource-router';
import ViewTopicResource from './resources/view-topic';

const PageLoader = () => {
    const [
        {
            match: {
                params: { id },
            },
        },
    ] = useRouter();
    invariant(id);
    const { queryReference } = useRelayResource<topicQuery>(ViewTopicResource);
    if (!queryReference) return null;
    return <ViewTopic topicId={id} queryReference={queryReference} />;
};

export const viewTopicRoute = () => {
    const paperAppearance: PaperAppearance = isUseHelpCenterRefresh() ? 'xwide' : 'normal';

    const layoutProps = {
        paperAppearance,
        fadeInSearchModal: true,
    };

    return createRouteWithLayout(
        {
            name: 'view-topic',
            path: '/topic/:id',
            exact: true,
            component: PageLoader,
            resources: [ViewTopicResource],
        },
        ScreenName.VIEW_TOPIC,
        layoutProps
    );
};
