import { getMeta } from '@atlassian/help-center-common-util/meta';

// Extracted from module @atlassiansox/analytics-web-client
// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export enum EnvType {
    LOCAL = 'local',
    DEV = 'dev',
    STAGING = 'staging',
    PROD = 'prod',
}

export interface Env {
    environment: EnvType;
    featureFlags: {
        [key: string]: boolean;
    };
    wacLink: string;
    contactLink: string;
    headless: boolean;
    serviceDeskVersion: string;
    contextPath: string;
    xsrfToken: string;
    baseUrl: string;
    relativeBaseUrl: string;
    anonymousEnabled: boolean;
    requestIntroEnabled: boolean;
    mediaApiExternalUrl?: string;
    mediaApiAppClientId?: string;
    fabricEmojiUrl?: string;
    jiraDisplayUrl: string;
    helpCenterHomePageSlug: string;
    helpCenterType: string;
    traceId?: string;
    featuredValues?: {
        [key: string]: boolean;
    };
    siteDefaultLanguageTag: string;
    hoistedProjectKey?: string;
    hoistedPortalId?: number;
}

function parseEnvironment() {
    const env = getMeta('environment');
    if (env === 'prod') {
        return EnvType.PROD;
    }
    if (env === 'staging') {
        return EnvType.STAGING;
    }
    return EnvType.DEV;
}

// Suppressing existing violation. Please fix this.
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const parseJson = (content?: string | null) => (content ? JSON.parse(content) : {});

const featureFlags = document.querySelector('meta[name=ajs-fe-feature-flags]');

const envElement = document.getElementById('envJson');
// Suppressing existing violation. Please fix this.
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const featureFlagsJson = parseJson(featureFlags && featureFlags.getAttribute('content'));

// Ignore any non-boolean flags and if feature flags are read from ajs-fe-feature-flags, map them to the old format
// (flagName: flagKey)
for (const key in featureFlagsJson) {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    if (featureFlagsJson[key].hasOwnProperty('value')) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const flagValue = featureFlagsJson[key].value;
        if (typeof flagValue === 'boolean') {
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            featureFlagsJson[key] = flagValue;
        } else {
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            delete featureFlagsJson[key];
        }
    }
}

let envData: Env;

export const getEnv = () => {
    if (envData) {
        return envData;
    }

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    envData = Object.freeze({
        ...parseJson(envElement && envElement.textContent),
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        featureFlags: featureFlagsJson,
        environment: parseEnvironment(),
    });

    return envData;
};

export const getIsPageServerSideRendered = () => {
    return window.__IS_PAGE_SERVER_SIDE_RENDERED__;
};

export const getIsMultiHCEnabled = () => {
    return getEnv().featuredValues?.multiHelpCenterEnabled;
};

export const getIsHCCustomizationEnabled = () => {
    return getEnv().featuredValues?.helpCenterCustomizationEnabled;
};

export const getJsmAIConfigEnabled: () => boolean = () => getEnv().featuredValues?.jsmAiConfigEnabled ?? false;
