import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { PortalSummary } from 'rest/featured-and-sorted-portals';
import type { fetchFeaturedAndSortedPortalsModel } from 'state/actions/featured-and-sorted-portals';
import { PortalCard } from 'view/help-center/portal-card';
import * as bp from 'view/styles/breakpoints';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import {
    Title,
    portalCardsContainerStyle,
    portalCardsContainerWithSidebarStyle,
    customScreenSizeBig,
} from 'view/styles/help-center';
import * as mixins from 'view/styles/mixins';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';

import { token } from '@atlaskit/tokens';
import { AutoFocus } from '@atlassian/help-center-common-component/auto-focus';

import { ThemedButton } from '@atlassian/help-center-common-component/themed-button';
import { ProjectCardSkeleton } from '../project-card-skeleton';
import i18n from './messages';
import type { SortBy } from 'rest/featured-and-sorted-portals/types';

export interface Props {
    defaultViewPortals: PortalSummary[];
    expandedViewPortals: PortalSummary[];
    sortBy: SortBy;
    total: number;
    isExpandedViewLoading: boolean;
    featuredPortalsCount: number;
    isSidebarOpen: boolean;
    isPageInEditMode?: boolean;
    fetchFeaturedAndSortedPortals: typeof fetchFeaturedAndSortedPortalsModel;
}
interface State {
    expand: boolean;
}

export default class FeaturedAndSortedPortalsCardListDumb extends React.Component<Props, State> {
    state = {
        expand: false,
    };

    onLoadMoreProjects = () => {
        const { defaultViewPortals, expandedViewPortals, total } = this.props;

        this.setState({ expand: true });
        /* Only in case exploreAll has not been clicked before during in app navigation or we don't have complete data*/
        if (defaultViewPortals.length + expandedViewPortals.length < total) {
            this.props.fetchFeaturedAndSortedPortals({ sortBy: this.props.sortBy, exploreAll: true }, false);
        }
    };

    onCollapseOtherProjects = () => {
        this.setState({ expand: false });
    };

    loaderState = () => {
        return (
            <>
                {Array.from({ length: 9 }).map((_, index) => (
                    <ProjectCardSkeleton key={index} isBasic />
                ))}
            </>
        );
    };

    getFeaturedAndSortedPortalsList = (portals: PortalSummary[], shouldFocus: boolean = false) => {
        const { expand } = this.state;
        return (
            <>
                {portals.map(({ id, name, description, logoUrl }, index) => {
                    /**
                     * Have auto focus on the last card of default view section
                     */
                    const Focus = shouldFocus && index === portals.length - 1 && expand ? AutoFocus : React.Fragment;

                    return (
                        <FeaturedAndSortedPortalsListItem
                            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                            className={this.props.isSidebarOpen ? 'sidebar-opened' : 'sidebar-closed'}
                            key={`portalCard_${id}`}
                            data-testid="portal-cards-list-item"
                        >
                            <Focus>
                                <PortalCard
                                    id={id}
                                    portalName={name}
                                    description={description}
                                    portalUrl={`/portal/${id}`}
                                    iconUrl={logoUrl}
                                />
                            </Focus>
                        </FeaturedAndSortedPortalsListItem>
                    );
                })}
            </>
        );
    };

    getMainTitle = () => {
        const { featuredPortalsCount } = this.props;
        if (featuredPortalsCount > 0) {
            return <FormattedMessage {...i18n.featuredPortalsLabel} />;
        }
        return <FormattedMessage {...i18n.remainingPortals} />;
    };

    render() {
        const {
            defaultViewPortals,
            expandedViewPortals,
            isExpandedViewLoading,
            total,
            featuredPortalsCount,
            isSidebarOpen,
            isPageInEditMode,
        } = this.props;
        const { expand } = this.state;
        const showExploreAllButton = !expand && total > defaultViewPortals.length;
        const remainingCount = total - defaultViewPortals.length;

        return (
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            <Root className={isSidebarOpen ? 'sidebar-opened' : 'sidebar-closed'} data-testid="portals-list">
                <ProjectsSection>
                    <Title data-test-id="primary-heading">{this.getMainTitle()}</Title>
                    <FeaturedAndSortedPortalsListContainer data-test-id="help-center-portal-cards">
                        {this.getFeaturedAndSortedPortalsList(defaultViewPortals, true)}
                    </FeaturedAndSortedPortalsListContainer>

                    {showExploreAllButton && (
                        <ExploreAllButton
                            id="explore-all-projects"
                            onClick={this.onLoadMoreProjects}
                            appearance="link"
                            iconAfter={<ChevronDownIcon label="" />}
                            actionSubjectId="exploreAllProjects"
                            data-test-id="explore-all-button"
                            aria-expanded={expand}
                            tabIndex={isPageInEditMode ? -1 : 0}
                            isPageInEditMode={isPageInEditMode}
                        >
                            <FormattedMessage {...i18n.exploreAll} values={{ remainingCount }} />
                        </ExploreAllButton>
                    )}
                </ProjectsSection>
                {expand && (
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    <ProjectsSection className={featuredPortalsCount > 0 ? 'expanded-section' : ''}>
                        {featuredPortalsCount > 0 && (
                            <Title data-test-id="secondary-heading">
                                <FormattedMessage {...i18n.remainingProjectsByName} />
                            </Title>
                        )}
                        <FeaturedAndSortedPortalsListContainer data-test-id="help-center-portal-cards">
                            {isExpandedViewLoading
                                ? this.loaderState()
                                : this.getFeaturedAndSortedPortalsList(expandedViewPortals)}
                        </FeaturedAndSortedPortalsListContainer>
                        <CollapseOtherButton
                            id="collapse-other-projects"
                            onClick={this.onCollapseOtherProjects}
                            appearance="link"
                            iconAfter={<ChevronUpIcon label="chevron-up" />}
                            actionSubjectId="collapseOtherProjects"
                            isDisabled={isExpandedViewLoading}
                            data-test-id="collapse-other-button"
                            aria-expanded={expand}
                            tabIndex={isPageInEditMode ? -1 : 0}
                            isPageInEditMode={isPageInEditMode}
                        >
                            <FormattedMessage {...i18n.collapseOtherProjects} />
                        </CollapseOtherButton>
                    </ProjectsSection>
                )}
            </Root>
        );
    }
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Root = styled.div`
    ${mixins.flexCenter};
    flex-direction: column;
    padding: ${token('space.100', '8px')} ${token('space.100', '8px')} ${token('space.600', '48px')}
        ${token('space.100', '8px')};
    align-self: center;
    width: 100%;
    &.sidebar-opened {
        ${portalCardsContainerWithSidebarStyle}
    }
    &.sidebar-closed {
        ${portalCardsContainerStyle}
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FeaturedAndSortedPortalsListContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -${grid.multiple(5).px} 0;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FeaturedAndSortedPortalsListItem = styled.div`
    display: flex;
    justify-content: center;
    margin: ${token('space.200', '16px')};

    margin: ${token('space.200', '16px')} ${token('space.250', '20px')};

    &.sidebar-opened {
        ${bp.fromCustom(customScreenSizeBig).css`
            margin: ${token('space.200', '16px')} ${grid.multiple(4.75).px};
        `}
    }
    &.sidebar-closed {
        ${bp.fromLarge.css`
            margin: ${token('space.200', '16px')} ${grid.multiple(4.75).px};
        `}
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ProjectsSection = styled.section`
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin-top: ${token('space.300', '24px')};

    &.expanded-section {
        margin-top: ${token('space.600', '48px')};
    }
`;

// @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ExploreAllButton = styled<{ isPageInEditMode: boolean }>(ThemedButton)`
    && {
        ${fonts.h500};
        padding: ${token('space.100', '8px')};

        &:hover,
        &:active,
        &:focus {
            ${fonts.h500};
            padding: ${token('space.100', '8px')};
        }
    }
    pointer-events: ${(props) => (props.isPageInEditMode ? 'none' : 'auto')};
`;

// @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CollapseOtherButton = styled<{ isDisabled: boolean; isPageInEditMode: boolean }>(ThemedButton)`
    && {
        ${fonts.h500};
        color: ${(props) => (props.isDisabled ? 'lightgray !important' : '')};
        padding: ${token('space.100', '8px')};

        &:hover,
        &:active,
        &:focus {
            ${fonts.h500};
            padding: ${token('space.100', '8px')};
        }
    }
    pointer-events: ${(props) => (props.isPageInEditMode ? 'none' : 'auto')};
`;
