import type { State } from 'state';
import type { UserRequestsModel, UserState } from 'state/persisted/user';

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export enum AvatarSizes {
    XSMALL = 'xsmall',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    XLARGE = 'xlarge',
}

export const isLoggedIn = (state: State) => state.persisted.user.isLoggedIn;

export const isUserLoaded = (state: State) => state.persisted.user.isLoaded;

export const getLocale = (state: State) => state.persisted.user.language.key;

export const getAvatar = (state: State, size: AvatarSizes): string | undefined => {
    if (state.persisted.user.avatars && size) {
        return state.persisted.user.avatars[size];
    }

    return undefined;
};

export const getAvatarSrc = (state: State) => getAvatar(state, AvatarSizes.XLARGE);

export const getUserRequests = (state: State): UserRequestsModel => {
    return state.persisted.user.userRequests;
};

export const getMyRequestCount = (state: State): number | undefined => {
    return state.persisted.user.userRequests.myRequestCount;
};

export const getPendingApprovalCount = (state: State): number | undefined => {
    return state.persisted.user.userRequests.pendingApprovalCount;
};

export const getApprovalCount = (state: State): number | undefined => {
    return state.persisted.user.userRequests.approvalCount;
};

export const getCustomMenuLinks = (state: State) => state.persisted.user.customMenuLinks;

export const getCanAdministerJIRA = (state: State) => {
    return state.persisted.user.canAdministerJIRA;
};

export const isAdEnabled = (state: State) => state.persisted.user.isAdEnabled;
export const getCanCreateProject = (state: State) => state.persisted.user.canCreateProject;

// can remove this once we refactor / move to new analytis as we don't want to use this property if possible
export const getAgentForPortal = (state: State) => {
    return state.persisted.user.agentForPortal;
};

export const getAccountId = (state: State) => {
    return state.persisted.user.accountId;
};

export const getDisplayName = (state: State) => {
    return state.persisted.user.displayName;
};

export const getEmail = (state: State) => {
    return state.persisted.user.email;
};

export const getUserState = (state: State): UserState => {
    return state.persisted.user;
};

export const isRequestDetailsShowHideAnnouncementVisible = (state: State): boolean => {
    return state.persisted.user.requestDetailsShowHideAnnouncementVisible;
};

export const isAtlassianAccountManaged = (state: State): boolean => {
    return state.persisted.user.atlassianAccountManaged;
};
