import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { operationalEvent } from 'util/analytics/events';
import { intlErrorMatch } from 'util/intl-error-match';
import { Provider as ReduxProvider } from 'react-redux';
import type { Middleware } from 'redux';
import { isWrmOptimizationsEnabled } from 'feature-flags';
import { memoize, keys } from 'lodash';
import type { ReactIntlError } from 'react-intl-next';
import { IntlProvider as IntlProviderNext } from 'react-intl-next';
import { RelayEnvironmentProvider } from 'react-relay';
import type { State } from 'state';
import { UPDATE_USER_MODEL } from 'state/actions/user';
import createStore from 'state/create-store';
import { getLocale } from 'state/selectors/user';
import type { AnalyticsWebClient } from '@atlaskit/analytics-listeners';
import FabricAnalyticsListeners, { FabricChannel } from '@atlaskit/analytics-listeners';
import { AnalyticsListener } from '@atlaskit/analytics-next';
import { ANALYTICS_BRIDGE_CHANNEL } from '@atlassian/analytics-bridge';
import { EngagementProvider } from '@atlassian/help-center-common-component/engagement-provider';
import { FeatureFlagProvider } from '@atlassian/help-center-common-component/feature-flag';
import { TFECacheProvider } from '@atlassian/help-center-common-component/forge-ui/tfe-cache-provider';
import { setAccountMetaTag } from '@atlassian/help-center-common-util/account';
import { sendEvent, sendAtlaskitUIEvent } from '@atlassian/help-center-common-util/analytics';
import { getAnalyticsWebClient } from '@atlassian/help-center-common-util/analytics/client';
import { FeatureFlagAnalyticsContext } from '@atlassian/help-center-common-util/analytics/feature-flag-context';
import { PortalAnalyticsContext } from '@atlassian/help-center-common-util/analytics/portal-context';
import { CoordinationClientProvider } from '@atlassian/help-center-common-util/coordination-client-provider';
import { getMessages } from '@atlassian/help-center-common-util/i18n-messages';
import { addSuportedLocaleData, getLanguageFromLocale } from '@atlassian/help-center-common-util/locale-data';
import { getRelayEnvironment } from '@atlassian/help-center-common-util/relay';
import { DeferredWrmResourceProvider } from '@atlassian/help-center-common-util/wrm';
import { CustomDomainsAnalyticsProvider } from './util/analytics/custom-domains-analytics-provider';
import { HelpSeekerTypeProvider } from './util/analytics/help-seeker-type-provider';
import { sendEventFromAnalyticsBridge } from './util/analytics/listener';
import { MetaDataProvider } from './view/meta-data-provider';
import type { AtlassianHelpConfig } from 'types';

const initialState: State = {} as State;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const accountTypeMetaTagMiddleware: Middleware = () => (next) => (action: any) => {
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (action.type === UPDATE_USER_MODEL) {
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setAccountMetaTag(action.payload.atlassianAccountManaged, action.payload.accountId);
    }
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return next(action);
};

const { store, injectReducer } = createStore({ initialState, middleware: [accountTypeMetaTagMiddleware] });

interface Props {
    config: AtlassianHelpConfig;
}

addSuportedLocaleData();

export const onIntlError = (intlError: string): void => {
    try {
        /**
         * Do not report to sentry if missing translations are from external packages.
         * Extend the regexp to add support for multiple packages
         */
        if (!intlError) return;
        const isExternalTranslation = intlError.match(/Missing message: "fabric\.(editor|media|theme|emoji)\..*/);

        const { errorKey, errorLocale, isNonFinal } = intlErrorMatch(intlError);

        // According to this document https://hello.atlassian.net/wiki/spaces/i18n/pages/647802766/Localized+Language+Support+Coverage+and+Strategy after 2018 we have discontinued support for these locales
        const isUnSupportedLocale: boolean = !!intlError.match(/.*Missing message.*for locale "(ro|pt|no|sk|et|is)".*/);

        if (isExternalTranslation || isUnSupportedLocale) {
            return;
        }

        operationalEvent({
            attributes: {
                isNonFinal,
                key: errorKey,
                locale: errorLocale,
            },
            actionSubject: 'intl_translation',
            action: 'missing',
            source: 'unknownSource',
        });
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Failed to report intl error: ${intlError}`, err);
    }
};

export const onIntlNextError = (intlError: ReactIntlError): void => {
    onIntlError(`IntlV5: ${intlError.message}`);
};

export class AppProviders extends React.Component<React.PropsWithChildren<Props>> {
    render() {
        const { config } = this.props;
        const analyticsClient = getAnalyticsWebClient().getInstance() as unknown as AnalyticsWebClient;

        const envFeatureFlags = (store.getState() as State).persisted.env.featureFlags;
        const configFeatureFlags = config?.featureFlags;
        const memoizedFeatureFlags = memoize(
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            (envFFs, configFFs) => ({
                ...envFFs,
                ...configFFs,
            }),
            (...args) =>
                keys({
                    ...args[0],
                    ...args[1],
                })
        );
        const WrmResourceProvider = isWrmOptimizationsEnabled() ? DeferredWrmResourceProvider : React.Fragment;
        return (
            <RelayEnvironmentProvider environment={getRelayEnvironment()}>
                <CustomDomainsAnalyticsProvider baseUrl={config.baseUrl}>
                    <FabricAnalyticsListeners client={analyticsClient} excludedChannels={[FabricChannel.atlaskit]}>
                        <EngagementProvider config={config} analyticsClientInstance={analyticsClient}>
                            <AnalyticsListener onEvent={sendAtlaskitUIEvent} channel="atlaskit">
                                <AnalyticsListener
                                    onEvent={sendEventFromAnalyticsBridge}
                                    channel={ANALYTICS_BRIDGE_CHANNEL}
                                >
                                    <AnalyticsListener onEvent={sendEvent}>
                                        <IntlProvider
                                            messages={getMessages()}
                                            locale={getLanguageFromLocale(getLocale(store.getState() as State))}
                                            onError={onIntlError}
                                        >
                                            <IntlProviderNext
                                                messages={getMessages()}
                                                locale={getLanguageFromLocale(getLocale(store.getState() as State))}
                                                onError={onIntlNextError}
                                            >
                                                {/* Suppressing existing violation. Please fix this. */}
                                                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */}
                                                <ReduxProvider store={store as any}>
                                                    <MetaDataProvider config={this.props.config}>
                                                        <WrmResourceProvider>
                                                            <PortalAnalyticsContext>
                                                                <HelpSeekerTypeProvider>
                                                                    <FeatureFlagProvider
                                                                        // Suppressing existing violation. Please fix this.
                                                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                                                        featureFlags={memoizedFeatureFlags(
                                                                            envFeatureFlags,
                                                                            configFeatureFlags
                                                                        )}
                                                                    >
                                                                        <FeatureFlagAnalyticsContext>
                                                                            <CoordinationClientProvider>
                                                                                <TFECacheProvider>
                                                                                    {this.props.children}
                                                                                </TFECacheProvider>
                                                                            </CoordinationClientProvider>
                                                                        </FeatureFlagAnalyticsContext>
                                                                    </FeatureFlagProvider>
                                                                </HelpSeekerTypeProvider>
                                                            </PortalAnalyticsContext>
                                                        </WrmResourceProvider>
                                                    </MetaDataProvider>
                                                </ReduxProvider>
                                            </IntlProviderNext>
                                        </IntlProvider>
                                    </AnalyticsListener>
                                </AnalyticsListener>
                            </AnalyticsListener>
                        </EngagementProvider>
                    </FabricAnalyticsListeners>
                </CustomDomainsAnalyticsProvider>
            </RelayEnvironmentProvider>
        );
    }
}

export { store, injectReducer };
