import type { FormattedMessage } from 'react-intl';
import type {
    SaveUserProfile,
    SaveUserProfileFailure,
    SetUserProfileValidationErrors,
    SaveUserProfileSuccess,
    ShowChangePasswordDialog,
    CloseChangePasswordDialog,
    ChangePassword,
    ChangePasswordFailure,
    ChangePasswordSuccess,
    ValidatePasswordSuccess,
    ForgotPassword,
    ForgotPasswordFailure,
    ForgotPasswordSuccess,
} from 'state/actions/user';
import {
    SAVE_USER_PROFILE,
    SAVE_USER_PROFILE_SUCCESS,
    SAVE_USER_PROFILE_FAILURE,
    SET_USER_PROFILE_VALIDATION_ERRORS,
    SHOW_CHANGE_PASSWORD_DIALOG,
    CLOSE_CHANGE_PASSWORD_DIALOG,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    VALIDATE_PASSWORD_SUCCESS,
    USER_PROFILE_FORGOT_PASSWORD,
    USER_PROFILE_FORGOT_PASSWORD_FAILURE,
    USER_PROFILE_FORGOT_PASSWORD_SUCCESS,
} from 'state/actions/user';
import type { DefaultError } from '@atlassian/help-center-common-util/errors';

export interface ChangePasswordErrors {
    currentPasswordError?: string | FormattedMessage.MessageDescriptor;
    newPasswordError?: string | FormattedMessage.MessageDescriptor;
    confirmPasswordError?: string | FormattedMessage.MessageDescriptor;
    generalError?: DefaultError;
}

export interface SaveUserProfileErrors {
    nameError?: string;
    timezoneError?: string;
}

export interface UserProfileUIState {
    saveInProgress: boolean;
    changePasswordInProgress: boolean;
    showChangePasswordDialog: boolean;
    changePasswordErrors: ChangePasswordErrors;
    saveUserProfileErrors: SaveUserProfileErrors;
    sendForgotPasswordEmailInProgress: boolean;
}

export type HandledActions =
    | SaveUserProfile
    | SaveUserProfileFailure
    | SetUserProfileValidationErrors
    | SaveUserProfileSuccess
    | ShowChangePasswordDialog
    | CloseChangePasswordDialog
    | ChangePassword
    | ChangePasswordFailure
    | ChangePasswordSuccess
    | ValidatePasswordSuccess
    | ForgotPassword
    | ForgotPasswordFailure
    | ForgotPasswordSuccess;

const defaultState: UserProfileUIState = {
    saveInProgress: false,
    changePasswordInProgress: false,
    showChangePasswordDialog: false,
    changePasswordErrors: {},
    saveUserProfileErrors: {},
    sendForgotPasswordEmailInProgress: false,
};

export function userProfileReducer(
    state: UserProfileUIState = defaultState,
    action: HandledActions
): UserProfileUIState {
    switch (action.type) {
        case SAVE_USER_PROFILE:
            return handleSaveUserProfile(state);
        case SAVE_USER_PROFILE_FAILURE:
            return handleSaveUserProfileFailure(state);
        case SET_USER_PROFILE_VALIDATION_ERRORS:
            return handleSetUserProfileValidationErrors(state, action);
        case SAVE_USER_PROFILE_SUCCESS:
            return handleSaveUserProfileSuccess(state);
        case SHOW_CHANGE_PASSWORD_DIALOG:
            return handleShowChangePasswordDialog(state);
        case CLOSE_CHANGE_PASSWORD_DIALOG:
            return handleCloseChangePasswordDialog(state);
        case CHANGE_PASSWORD:
            return handleChangePassword(state);
        case CHANGE_PASSWORD_SUCCESS:
            return handleChangePasswordSuccess(state);
        case CHANGE_PASSWORD_FAILURE:
            return handleChangePasswordFailure(state, action);
        case VALIDATE_PASSWORD_SUCCESS:
            return handleValidatePasswordSuccess(state);
        case USER_PROFILE_FORGOT_PASSWORD:
            return handleForgotPassword(state);
        case USER_PROFILE_FORGOT_PASSWORD_FAILURE:
            return handleForgotPasswordFailure(state);
        case USER_PROFILE_FORGOT_PASSWORD_SUCCESS:
            return handleForgotPasswordSuccess(state);
        default:
            return state;
    }
}

const handleSaveUserProfile = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    saveInProgress: true,
});

const handleSaveUserProfileFailure = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    saveInProgress: false,
});

function handleSetUserProfileValidationErrors(
    state: UserProfileUIState,
    action: SetUserProfileValidationErrors
): UserProfileUIState {
    const { nameError, timezoneError } = action.payload;
    return {
        ...state,
        saveUserProfileErrors: {
            nameError,
            timezoneError,
        },
    };
}

const handleSaveUserProfileSuccess = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    saveInProgress: false,
});

const handleShowChangePasswordDialog = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    showChangePasswordDialog: true,
});

const handleCloseChangePasswordDialog = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    showChangePasswordDialog: false,
    changePasswordErrors: {},
});

const handleValidatePasswordSuccess = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    changePasswordErrors: {},
});

const handleChangePassword = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    changePasswordInProgress: true,
    changePasswordErrors: {},
});

const handleChangePasswordSuccess = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    showChangePasswordDialog: false,
    changePasswordInProgress: false,
    changePasswordErrors: {},
});

const handleChangePasswordFailure = (state: UserProfileUIState, action: ChangePasswordFailure): UserProfileUIState => ({
    ...state,
    changePasswordInProgress: false,
    changePasswordErrors: {
        currentPasswordError: action.payload.currentPasswordError,
        newPasswordError: action.payload.newPasswordError,
        confirmPasswordError: action.payload.confirmPasswordError,
        generalError: action.payload.generalError,
    },
});

const handleForgotPassword = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    sendForgotPasswordEmailInProgress: true,
    showChangePasswordDialog: true,
});

const handleForgotPasswordFailure = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    sendForgotPasswordEmailInProgress: false,
    showChangePasswordDialog: true,
});

const handleForgotPasswordSuccess = (state: UserProfileUIState): UserProfileUIState => ({
    ...state,
    sendForgotPasswordEmailInProgress: false,
    showChangePasswordDialog: false,
});
